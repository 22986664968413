import { Box, Typography } from "@mui/material";
import learning from "@assets/images/Learning-bro.png";
import { COLORS } from "@/constants/Colors/colors";
import { useSelector } from "react-redux";

export default function Introduction() {
  const globalState = useSelector((state) => state?.global);

  return (
    <>
      <Box position={"relative"} sx={{ overflowX: "hidden", color: "white" }}>
        <div
          style={
            window.innerWidth > 600
              ? { display: "flex", width: "100%" }
              : {
                  display: "flex",
                  flexDirection: "column-reverse",
                  width: "100%",
                }
          }
        >
          <Box
            sx={
              window.innerWidth > 600
                ? {
                    width: "60%",
                    bgcolor: COLORS.SECONDARY.MAIN,
                    padding: "40px",
                  }
                : { bgcolor: COLORS.SECONDARY.MAIN, padding: "10px" }
            }
          >
            <Typography fontSize={window.innerWidth > 600 ? 60 : 40} mb={6}>
              مرحبًا بك في برنامج النسخة التجريبية
            </Typography>
            <Typography
              fontSize={window.innerWidth > 600 ? 18 : 16}
              lineHeight={2.5}
            >
              يسرنا أن نقدم لك الفرصة لاستكشاف منتجنا قبل اتخاذ قرار الشراء.
              هدفنا هو ضمان أن تكون تجربتك مع برنامجنا مرضية وتلبي توقعاتك. هذا
              البرنامج التجريبي يمنحك إمكانية الوصول الكامل إلى جميع الميزات
              والوظائف لفترة محدودة. نحثك على تجربة كل جانب من جوانب البرنامج
              وتقديم أي ملاحظات أو استفسارات قد تكون لديك. يرجى مراعاة أن النسخة
              التجريبية هي فرصة لاختبار البرنامج وتحديد ما إذا كان يلبي
              احتياجاتك قبل الشراء النهائي. بعد انتهاء فترة النسخة التجريبية،
              يمكنك اتخاذ قرار الشراء بثقة. نشكرك على اهتمامك بمنتجنا، ونأمل أن
              تجد البرنامج ذا فائدة كبيرة لك. إذا كنت بحاجة إلى مساعدة أو لديك
              أي أسئلة، فلا تتردد في الاتصال بفريق الدعم الفني لدينا. نأمل أن
              تكون تجربتك إيجابية ومفيدة.
            </Typography>
          </Box>
          <Box
            sx={
              window.innerWidth > 600
                ? {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "40%",
                    bgcolor: globalState?.isDarkMode
                      ? COLORS.SOFT_DARK
                      : "white",
                  }
                : {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    bgcolor: globalState?.isDarkMode
                      ? COLORS.SOFT_DARK
                      : "white",
                  }
            }
          >
            <img src={learning} width={window.innerWidth > 600 ? 400 : 300} />
          </Box>
        </div>
      </Box>
    </>
  );
}
