import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { COLORS } from "@/constants/Colors/colors";
import { notifyError } from "@shared/Toast/toast";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export const UserCourseExam = ({ exam, index, bought }) => {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const navigate = useNavigate();
  return (
    <Box
      key={index}
      sx={{
        background: "#d1d1d1",
        display: "flex",
        justifyContent: "space-between",
        borderRadius: "10px",
        borderBottom: "5px solid white",
        textAlign: "right",
        marginBottom: "20px",
        position: "relative",
        boxShadow: `0px 4px 0px ${COLORS.PRIMARY.MAIN}`,
      }}
    >
      <div
        style={{
          background: "#2A2A28",
          padding: "20px",
          borderRadius: "0px 10px 10px 0px",
          fontSize: "20px",
          color: "white",
        }}
      >
        {index < 10 ? `0${index + 1}` : index}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          padding: "0px 20px",
          color: "black",
        }}
      >
        <Typography fontFamily={"Noor-bold"}>{exam.title}</Typography>
        {exam.exam_answers.length > 0 && (
          <Typography fontFamily={"Noor-bold"} fontSize={24}>
            {exam.exam_answers[0].grade == null
              ? exam.grade + "/-"
              : exam.exam_answers[0].grade + "/" + exam.grade}
          </Typography>
        )}
        <div>
          {new Date() > new Date(exam.starts_at) &&
            (exam.exam_answers.length == 0 ||
              exam.exam_answers[0].grade == null ||
              exam.exam_answers[0].grade < exam.grade / 2) && (
              <Button
                onClick={() => {
                  if (bought) {
                    navigate(`course-overall-exam/${exam.id}`);
                  } else {
                    notifyError(
                      lang == "ar"
                        ? "يجب شراء الكورس اولا"
                        : "You must purchase the course first."
                    );
                  }
                }}
                sx={{
                  bgcolor: COLORS.PRIMARY.MAIN,
                  padding: "7px 20px",
                  color: "white",
                  transition: "0.4s",
                  "&:hover": {
                    bgcolor: COLORS.WHITE,
                    color: COLORS.PRIMARY.MAIN,
                  },
                }}
              >
                {exam.exam_answers.length == 0
                  ? lang == "ar"
                    ? "ابدأ الإمتحان الآن"
                    : "Start the exam now"
                  : lang == "ar"
                  ? "إعادة الإمتحان"
                  : "Re-examination"}
              </Button>
            )}
          {new Date() < new Date(exam.starts_at) && (
            <Typography
              fontFamily={"Noor-bold"}
              color={"black"}
              fontSize={"18"}
            >
              {lang == "ar"
                ? "لم يبدأ الإمتحان بعد"
                : "The exam has not started yet"}
            </Typography>
          )}
        </div>
      </div>
    </Box>
  );
};
