import { Box, Button, ListItem, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import { CourseExam } from "./atoms/CourseExam";
import useCustomQuery from "@/hooks/useCustomQuery";
import { HashLoader } from "react-spinners";
import { COLORS } from "@/constants/Colors/colors";

export function CourseExamsList({ startFunc, setExamId, examId, exam }) {
  const [countdown, setCountdown] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [started, setStarted] = useState(false);
  const [diff, setDiff] = useState(null);
  const startDate = new Date(exam?.data?.starts_at);
  const currentDate = Date.now();
  if (diff == null && startDate - currentDate) {
    setDiff(startDate - currentDate);
    setCountdown(startDate - currentDate);
  }
  //homework?.data?.data?.starts_at
  useEffect(() => {
    let timer;

    if (diff >= 0) {
      timer = setInterval(() => {
        if (diff >= 0) {
          setDiff((d) => d - 1000);
          setCountdown((c) => c - 1);
        }
      }, 1000);
    } else {
      setDisabled(false);
    }
    return () => clearInterval(timer);
  }, [diff]);

  const formatCountdown = () => {
    const seconds = Math.floor((diff / 1000) % 60);
    const minutes = Math.floor((diff / 1000 / 60) % 60);
    const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));

    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  };

  const { mid } = useParams();

  const {
    isError: isExamsError,
    isPending: isExamsLoading,
    error: examsError,
  } = useCustomQuery({
    key: ["exams", mid],
    url: `exam/getExam?lesson_module_id=${mid}`,
    retry: (count, error) => {
      if (error.response.data.message === "There's no exam for this lesson")
        return false;
      else if (count < 3) return true;
    },
    select: (data) => data,
  });

  const empty = (
    <Typography fontSize={18} fontFamily={"Noor-bold"} textAlign={"center"}>
      لا يوجد امتحان
    </Typography>
  );

  const spinner = (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <HashLoader color={COLORS.PRIMARY.MAIN} />
    </div>
  );

  const content = (
    <Box>
      <ListItem
        sx={{
          padding: "30px",
          bgcolor: COLORS.DARK_GRAY,
          borderRadius: "10px",
          textAlign: "center",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          color: "white",
          boxShadow: `0px 6px 0px ${COLORS.PRIMARY.MAIN}`,
          marginBottom: "30px",
        }}
      >
        <Box>
          <Typography fontFamily={"Noor-bold"} fontSize={24} mb={1}>
            {exam?.data?.title.length > 30
              ? `${exam?.data?.title.slice(0, 30)}...`
              : exam?.data?.title}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography fontFamily={"Noor-bold"} fontSize={20}>
              المده الزمنيه: {exam?.data?.exam_time} دقيقة
            </Typography>
          </Box>
        </Box>

        {!disabled && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Typography fontFamily={"Noor-bold"} fontSize={40}>
              {exam?.data?.grade}
            </Typography>
            <span
              style={{
                margin: "0px 10px",
                fontSize: "30px",
                fontWeight: "700",
              }}
            >
              {" "}
              /{" "}
            </span>
            <Typography fontFamily={"Noor-bold"} fontSize={40}>
              {/* {index} */}
              {exam?.data?.examAnswer &&
              exam?.data?.examAnswer.grade !== undefined
                ? exam?.data?.examAnswer.grade || "0"
                : "-"}
            </Typography>
          </Box>
        )}

        <Box borderRadius={"4px"} overflow={"hidden"}>
          {disabled && (
            <Box sx={{ bgcolor: "black", color: "white" }}>
              {formatCountdown()}
            </Box>
          )}
          {(!exam?.data?.examAnswer ||
            exam?.data?.examAnswer.grade < exam?.data?.grade / 2) && (
            <Button
              onClick={() => {
                setStarted(true);
              }}
              disabled={disabled}
              sx={{
                bgcolor: `${disabled ? "grey.300" : COLORS.PRIMARY.MAIN}`,
                padding: "15px 20px",
                width: "150px",
                color: "white",
                transition: "0.4s",
                borderRadius: "0",
                fontFamily: "Noor-regular",
                "&:disabled": {
                  color: "grey.600",
                },
                "&:hover": {
                  bgcolor: COLORS.SECONDARY.MAIN,
                },
              }}
            >
              ابدأ الان
            </Button>
          )}
        </Box>
      </ListItem>
    </Box>
  );
  return (
    <>
      {isExamsLoading ? (
        spinner
      ) : isExamsError ? (
        empty
      ) : started ? (
        <CourseExam examId={examId} setStarted={setStarted} />
      ) : (
        content
      )}
    </>
  );
}
