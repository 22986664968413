import { COLORS } from "@/constants/Colors/colors";
import {
  Box,
  Paper,
  Table,
  TableContainer,
  Typography,
  TableBody,
  IconButton,
  TableCell,
  TableRow,
  Button,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomTableHead from "@/components/Layout/TableHead/CustomTableHead";
import CustomNoData from "@/components/Layout/NoDataTableRow/CustomNoData";
import CustomTableLoading from "@/components/Layout/TableRowLoading/CustomTableLoading";
import { useGetData } from "@/hooks/useGetData";
import CreateLink from "../components/CreateLink";

import EditIcon from "@mui/icons-material/Edit";
import { useState } from "react";
import EditLink from "../components/EditLink";
import { useMutate } from "@/hooks/useMutate";
import { notifyError, notifySuccess } from "@shared/Toast/toast";
import { useSelector } from "react-redux";

const Settings = () => {
  const lang = useSelector((state) => state?.global?.lang);
  const [openModal, setOpenModal] = useState({
    createLink: false,
    editLink: false,
  });
  const [editedData, setEditedData] = useState({});

  const {
    data: tableData,
    isPending,
    refetch,
  } = useGetData("socials", "social links");
  const { mutateAsync } = useMutate();

  // ------------ function ------------
  const handleCreationClose = () => {
    setOpenModal((prev) => {
      return { ...prev, createLink: false };
    });
  };
  const handleEditClose = () => {
    setOpenModal((prev) => {
      return { ...prev, editLink: false };
    });
  };

  return (
    <Box
      sx={{
        marginY: "30px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Typography
          fontFamily={"Noor-bold"}
          fontSize={32}
          sx={{ textShadow: `0px 2px 0px ${COLORS.PRIMARY.MAIN}` }}
        >
          {lang == "ar" ? "  الاعدادات" : "Settings"}
        </Typography>
      </Box>
      <Box sx={{ p: 5 }}>
        <Box>
          <Button
            onClick={() =>
              setOpenModal((prev) => {
                return { ...prev, createLink: true };
              })
            }
            sx={{
              background: COLORS.PRIMARY.MAIN,
              width: "200px",
              padding: "10px 25px",
              color: "white",
              cursor: "pointer",
              transition: "0.4s",
              "&:hover": {
                background: "white",
                color: COLORS.PRIMARY.MAIN,
              },
              position: "relative",
            }}
          >
            {lang == "ar" ? " انشاء لينك جديد" : "Create new link"}
          </Button>
        </Box>
        <TableContainer component={Paper} sx={{ width: "100%", my: 3 }}>
          <Table>
            <CustomTableHead
              headBackground={COLORS.PRIMARY.MAIN}
              tableHeaders={[
                lang == "ar" ? "الرقم" : "Number",
                lang == "ar" ? "المنصة" : "Platform",
                lang == "ar" ? "الرابط" : "Link",
                lang == "ar" ? "الاعدادات" : "Settings",
              ]}
            />

            {isPending ? (
              <CustomTableLoading width={"100%"} height={60} numOfCells={7} />
            ) : (
              <>
                {tableData?.data?.length > 0 ? (
                  <>
                    {tableData?.data?.map((cell, index) => {
                      return (
                        <TableBody key={index}>
                          <TableRow>
                            <TableCell
                              align="center"
                              component="th"
                              scope="row"
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell
                              align="center"
                              component="th"
                              scope="row"
                            >
                              {cell?.type}
                            </TableCell>
                            <TableCell
                              align="center"
                              component="th"
                              scope="row"
                            >
                              {cell?.link}
                            </TableCell>
                            <TableCell
                              align="center"
                              component="th"
                              scope="row"
                            >
                              <IconButton
                                className="settingIcon"
                                onClick={() => {
                                  setEditedData(cell);
                                  setOpenModal((prev) => {
                                    return { ...prev, editLink: true };
                                  });
                                }}
                                aria-label="delete"
                                size="small"
                                sx={{
                                  bgcolor: "#3adb44",
                                  marginX: "10px",

                                  "&:hover": {
                                    bgcolor: "#3adb44",
                                  },
                                }}
                              >
                                <span className="settingHover">
                                  {lang == "ar" ? "تعديل" : "Edit"}
                                </span>
                                <EditIcon
                                  fontSize="inherit"
                                  sx={{ color: "#fff" }}
                                />
                              </IconButton>

                              <IconButton
                                onClick={() => {
                                  mutateAsync({
                                    url: `socials/delete/${cell?.id}`,
                                    method: "DELETE",
                                  })
                                    ?.then(() => {
                                      notifySuccess(
                                        lang == "ar"
                                          ? "تم الحذف بنجاح"
                                          : "Deleted succefully"
                                      );
                                      refetch();
                                    })
                                    .catch(() => {
                                      notifyError(
                                        lang == "ar"
                                          ? "حدث خطأ اثناء تنفيذ المهمة"
                                          : "An error occurred while executing the task"
                                      );
                                    });
                                }}
                                className="settingIcon"
                                aria-label="delete"
                                size="small"
                                sx={{
                                  bgcolor: "#db3a3c",
                                  position: "relative",
                                  "&:hover": {
                                    bgcolor: "#db3a3c",
                                  },
                                }}
                              >
                                <span className="settingHover">
                                  {lang == "ar" ? "حذف" : "Delete"}
                                </span>
                                <DeleteIcon
                                  fontSize="inherit"
                                  sx={{ color: "#fff" }}
                                />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      );
                    })}
                  </>
                ) : (
                  <CustomNoData
                    rowText={lang == "ar" ? "لا يوجد روابط" : "No links"}
                    numOfCells={7}
                  />
                )}
              </>
            )}
          </Table>
        </TableContainer>
      </Box>

      <CreateLink
        openModal={openModal?.createLink}
        handleClose={handleCreationClose}
        refetch={refetch}
      />
      <EditLink
        openModal={openModal?.editLink}
        handleClose={handleEditClose}
        refetch={refetch}
        editedData={editedData}
      />
    </Box>
  );
};

export default Settings;
