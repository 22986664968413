import useCustomMutation from "@/hooks/useCustomMutation";

export default function useAddCourse({ onSettled, refetch }) {
  const { mutate, isPending } = useCustomMutation({
    url: "course/store",
    invalidate: ["courses"],
    method: "post",
    successMsg: "تمت اضافة الكورس بنجاح",
    onSettled,
    onSuccess: () => {
      refetch();
    },
  });

  function addCourse(data) {
    const formData = new FormData();
    Object.entries(data).forEach((key) => {
      if (
        key[0] === "subscription_days" &&
        data.subscription_days === undefined
      ) {
        formData.append("subscription_days", 0);
      } else {
        formData.append(key[0], data[key[0]]);
      }
    });
    formData.set("image", data.image[0]);
    console.log(formData, "formData add");
    mutate(formData);
  }

  return { addCourse, isPending };
}
