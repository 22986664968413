import React, { useState, useEffect } from "react";
import { Box, Button, ListItem, Radio, Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { api_url } from "@/constants/base_url";
import { currentUser } from "@shared/Helpers/encryptionHelpers";
import { useQueryClient } from "@tanstack/react-query";
import useCustomQuery from "@/hooks/useCustomQuery";
import useCustomMutation from "@/hooks/useCustomMutation";
import { HashLoader } from "react-spinners";
import { COLORS } from "@/constants/Colors/colors";
import ImageFullSize from "@shared/ImageFullSize/ImageFullSize";
import { useSelector } from "react-redux";

export const CourseHomeWorks = ({ setStarted, homeWork }) => {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showResults, setShowResults] = useState(false);
  const { mid } = useParams();
  const [selectedAnswer, setSelectedAnswer] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [displayFullImage, setDisplayFullImage] = useState(false);

  const queryClient = useQueryClient();
  useEffect(() => {
    if (displayFullImage) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [displayFullImage]);
  const [answers, setAnswers] = useState([]);

  const {
    data: homeworkQuestions,
    isPending: isHomeworkLoading,
    isSuccess,
  } = useCustomQuery({
    key: ["homework-questions", mid, lang],
    url: `homeWork/startHomeWork?lesson_module_id=${mid}&homework_id=${homeWork?.data?.id}`,
    lang,
    select: (data) => data,
    refetchOnMount: true,
    staleTime: 0,
    gcTime: 0,
  });
  useEffect(() => {
    if (isSuccess) {
      if (homeworkQuestions?.data?.data?.draft) {
        let newArr = new Array(
          homeworkQuestions?.data?.data?.homeWork?.questions?.length
        ).fill(0);
        for (let i = 0; i < homeworkQuestions?.data?.data?.draft.length; i++) {
          newArr[i] = homeworkQuestions?.data?.data?.draft[i];
        }
        setAnswers(newArr);
      } else {
        setAnswers(
          new Array(
            homeworkQuestions?.data?.data?.homeWork?.questions?.length
          ).fill(0)
        );
      }
      homeworkQuestions?.data?.data?.draft &&
        setSelectedAnswer(homeworkQuestions?.data?.data?.draft[0]);
    }
  }, [isSuccess]);
  const { mutate, isPending } = useCustomMutation({
    url: `homeWork/submitHomeWork`,
    onSuccess: () => {
      queryClient.invalidateQueries(["homeworkData"]);
      setStarted(false);
    },
    successMsg: lang == "ar" ? "تم الحفظ بنجاح" : "Saved succefully",
  });

  if (isHomeworkLoading) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "30px",
          marginBottom: "30px",
        }}
      >
        <HashLoader color={COLORS.PRIMARY.MAIN} />
      </div>
    );
  }
  return (
    <>
      <Box
        width={"100%"}
        height={60}
        sx={{
          bgcolor: COLORS.PRIMARY.MAIN,
          display: "flex",
          alignItems: "center",
          paddingX: "10px",
        }}
        color={"white"}
      >
        <Typography fontFamily={"Noor-bold"} fontSize={28} color={"white"}>
          {homeworkQuestions?.data?.data?.homeWork?.title}
        </Typography>
      </Box>
      <Box
        style={{ transition: "all 0.5s" }}
        sx={{
          display: "flex",
          gap: "10px",
          flexDirection: "column",
          padding: "30px",
          bgcolor: "grey.300",
          margin: "0 auto",
          textAlign: "center",
        }}
      >
        <Typography
          fontFamily={"Noor-bold"}
          fontSize={32}
          color={COLORS.PRIMARY.MAIN}
        >
          {currentQuestion + 1}/
          {homeworkQuestions?.data?.data?.homeWork?.questions?.length}
        </Typography>
        <Typography fontFamily={"Noor-bold"} fontSize={24} color={"grey.800"}>
          {lang == "ar" ? "اختر الإجابة الصحيحة" : "Choose your answer"}
        </Typography>
        <Typography fontFamily={"Noor-bold"} fontSize={36} color={"grey.800"}>
          {
            homeworkQuestions?.data?.data?.homeWork?.questions[currentQuestion]
              ?.title
          }
        </Typography>
        <img
          src={`${api_url.slice(0, -7)}${
            homeworkQuestions?.data?.data?.homeWork?.questions[currentQuestion]
              ?.image
          }`}
          style={{
            borderRadius: "10px",
            width: "420px",
            cursor: "pointer",
            margin: "0 auto",
          }}
          alt=""
          onClick={() => {
            setDisplayFullImage(true);
            setSelectedImage(
              ` ${homeworkQuestions?.data?.data?.homeWork?.questions[currentQuestion]?.image}`
            );
          }}
        />
        {displayFullImage && (
          <ImageFullSize
            image={selectedImage}
            setDisplayFullImage={setDisplayFullImage}
          />
        )}
        <ul
          style={{
            listStyle: "none",
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "40px",
          }}
        >
          {homeworkQuestions?.data?.data?.homeWork?.questions[
            currentQuestion
          ]?.answers?.map((item, index) => (
            <ListItem
              style={{ transition: "all 0.5s" }}
              onClick={() => {
                let newArr = [...answers];
                newArr[currentQuestion] = item.id;
                setAnswers(newArr);
                setSelectedAnswer(item.id);
              }}
              key={index}
              sx={{
                bgcolor: `${
                  selectedAnswer === item.id || answers.includes(item.id)
                    ? COLORS.PRIMARY.MAIN
                    : COLORS.WHITE
                }`,
                cursor: "pointer",
                borderRadius: "4px",
                paddingY: "10px",
                paddingX: "20px",
              }}
            >
              <Typography
                style={{ transition: "all 0.5s" }}
                fontFamily={"Noor-bold"}
                fontSize={24}
                color={`${
                  selectedAnswer === item.id || answers.includes(item.id)
                    ? "white"
                    : "grey.800"
                }`}
              >
                {item.title}
              </Typography>
            </ListItem>
          ))}
        </ul>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          gap: "10px",
          bgcolor: "grey.300",
          paddingX: "30px",
          paddingY: "10px",
        }}
      >
        <Button
          onClick={() => {
            homeworkQuestions?.data?.data?.draft &&
              setSelectedAnswer(
                homeworkQuestions?.data?.data?.draft[currentQuestion - 1]
              );
            setCurrentQuestion(currentQuestion - 1);
          }}
          disabled={currentQuestion === 0}
          sx={{
            color: "white",
            bgcolor: COLORS.PRIMARY.MAIN,
            "&:hover": {
              bgcolor: COLORS.SECONDARY.MAIN,
            },
            "&:disabled": {
              bgcolor: "grey.400",
              color: "grey.700",
            },
          }}
        >
          {lang == "ar" ? "السابق" : "Previous"}
        </Button>
        <Button
          onClick={() => {
            mutate({
              submission_id: homeworkQuestions?.data?.data?.submission_id,
              answers: answers,
              isDraft: 1,
              homework_id: homeWork?.data?.id,
            });
          }}
          sx={{
            color: "white",
            bgcolor: COLORS.PRIMARY.MAIN,
            "&:hover": {
              bgcolor: COLORS.SECONDARY.MAIN,
            },
          }}
          disabled={isPending}
        >
          {isPending ? (
            <HashLoader color={COLORS.WHITE} size={20} />
          ) : lang == "ar" ? (
            "حفظ مسودة"
          ) : (
            "Save draft"
          )}
        </Button>
        <Button
          disabled={isPending}
          onClick={() => {
            if (
              currentQuestion ===
              homeworkQuestions?.data?.data?.homeWork?.questions?.length - 1
            ) {
              mutate({
                submission_id: homeworkQuestions?.data?.data?.submission_id,
                answers: answers,
                isDraft: 0,
                homework_id: homeWork?.data?.id,
              });
            } else {
              homeworkQuestions?.data?.data?.draft &&
                setSelectedAnswer(
                  homeworkQuestions?.data?.data?.draft[currentQuestion + 1]
                );
              setCurrentQuestion(currentQuestion + 1);
            }
          }}
          sx={{
            color: "white",
            bgcolor: COLORS.PRIMARY.MAIN,
            "&:hover": {
              bgcolor: COLORS.SECONDARY.MAIN,
            },
          }}
        >
          {isPending ? (
            <HashLoader color={COLORS.WHITE} size={20} />
          ) : currentQuestion ===
            homeworkQuestions?.data?.data?.homeWork?.questions?.length - 1 ? (
            lang == "ar" ? (
              "حفظ"
            ) : (
              "Save"
            )
          ) : lang == "ar" ? (
            "التالي"
          ) : (
            "Next"
          )}
        </Button>
      </Box>
    </>
  );
};
