import { useState } from "react";
import { Box, IconButton } from "@mui/material";
import { COLORS } from "@/constants/Colors/colors";
import DeleteIcon from "@mui/icons-material/Delete";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DeleteDialog from "../../DeleteDialog";

export const ModuleFile = ({ file, id }) => {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [deleteName, setDeleteName] = useState();
  const [confirmMessage, setConfirmMessage] = useState("");
  const lang = useSelector((state) => state?.global?.lang);

  const handleDeleteClick = () => {
    setIsConfirmOpen(true);

    setConfirmMessage(
      lang == "ar"
        ? "هل انت متأكد من حذف الملف"
        : "Are you sure you want to delete the File?"
    );
    setDeleteData(file);
    setDeleteName(file?.url?.split("/")?.pop());
  };
  return (
    <Box
      sx={{
        width: "100%",
        paddingX: 2,
        color: COLORS.WHITE,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <InsertDriveFileIcon sx={{ color: COLORS.PRIMARY.MAIN }} />
        <a
          // href={`${api_url.slice(0, -8)}${file.url}`}
          href={`${file.link}`}
          style={{ color: COLORS.WHITE }}
        >
          {file?.name?.length > 20
            ? file?.name?.slice(0, 20) + "..."
            : file?.name}
        </a>
      </Box>
      <IconButton
        sx={{
          bgcolor: COLORS.DANGER,
          "&:hover": { bgcolor: COLORS.DANGER },
          width: 28,
          height: 28,
        }}
        onClick={handleDeleteClick}
      >
        <DeleteIcon fontSize="small" />
      </IconButton>
      <DeleteDialog
        isConfirmOpen={isConfirmOpen}
        setIsConfirmOpen={setIsConfirmOpen}
        deleteName={deleteName}
        deleteData={deleteData}
        confirmMessage={confirmMessage}
        id={id}
      />
    </Box>
  );
};
