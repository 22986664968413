import React from "react";
import { useUserSubsContext } from "../Context/UserSubsContext";
import { Box, Container } from "@mui/material";
import NoItemsText from "@shared/NoItemText/NoItemText";
import CourseCard from "@/modules/home/components/atoms/CourseCard";
import { api_url } from "@/constants/base_url";
import { Timer } from "@shared/Timer/Timer";
import { currentUser } from "@shared/Helpers/encryptionHelpers";
import { HashLoader } from "react-spinners";
import { COLORS } from "@/constants/Colors/colors";
import Paginator from "@shared/Pagination/Paginator";
import { useSelector } from "react-redux";

export const MySubscriptions = () => {
  const { data, isPending, setPage, page, totalPages } = useUserSubsContext();
  const globalState = useSelector((state) => state?.global);
  if (isPending) {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingY: 5,
        }}
      >
        <HashLoader color={COLORS.PRIMARY.MAIN} />
      </Box>
    );
  }
  return (
    <Box sx={{ minHeight: "84vh" }}>
      {data?.data?.data?.data?.length > 0 ? (
        <Box paddingY={5}>
          <Paginator page={page} setPage={setPage} totalPages={totalPages} />
          <Container
            maxWidth="2xl"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexWrap: "wrap",
              gap: 5,
            }}
          >
            {data?.data?.data?.data?.map((item, i) => (
              <Box sx={{ position: "relative", width: "fit-content" }}>
                <CourseCard
                  width={"350px"}
                  key={item.course.id}
                  img={`${api_url.slice(0, -7)}${item.course.image.slice(1)}`}
                  name={item.course.name}
                  price={item.course.price}
                  type={item.course.isPayByModule ? "بالحصة" : "بالكورس"}
                  teacher={item.course.teacher}
                  link={`/course/${item.course.id}`}
                  id={item.course.id}
                  description={item.course.description}
                  bought
                />
                <Timer
                  time={item.expires_at}
                  sx={{ position: "absolute", top: 0, left: 0 }}
                />
              </Box>
            ))}
          </Container>
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingY: 5,
          }}
        >
          <NoItemsText text={"ليس لديك اشتراكات حتي الآن"} />
        </Box>
      )}
    </Box>
  );
};
