import { currentUser } from "@shared/Helpers/encryptionHelpers";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Avatar, Typography, Button, Stack } from "@mui/material";
import boyImage from "@assets/images/boy.png";
import { COLORS } from "@/constants/Colors/colors";
import { Logout } from "./Logout";
import { Remove } from "./Remove";
import ModeTuggle from "@/modules/settings/components/ModeTuggle";
import { useSelector } from "react-redux";

export const UserAcc = () => {
  const [logout, setLogout] = useState(false);
  const [remove, setRemove] = useState(false);
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;

  const openLogout = () => setLogout(true);
  const openDelAcc = () => setRemove(true);
  const userData = currentUser();
  const navigate = useNavigate();
  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        position={"relative"}
        className="user-profile"
        sx={{
          cursor: "pointer",
          // borderBottom: '2px solid #2f8bc8',
          paddingBottom: "10px",
          marginLeft: 5,
          color: COLORS.WHITE,
        }}
      >
        <Stack direction="row" spacing={0} sx={{ marginLeft: "15px" }}>
          <Avatar
            alt="Remy Sharp"
            src={boyImage}
            sx={{
              width: 50,
              height: 50,
              boxShadow: 0,
              border: `2px solid ${
                globalState?.isDarkMode ? COLORS.WHITE : COLORS.BLACK
              }`,
            }}
          />
        </Stack>
        <Typography fontFamily={"Noor-bold"} fontSize={22} color={COLORS.WHITE}>
          {userData?.name}
        </Typography>

        <Box
          className="user-list"
          sx={{
            position: "absolute",
            top: 80,
            left: 0,
            bgcolor: COLORS.OFF_WHITE,
            padding: "20px 10px",
            borderRadius: "0px 0px 10px 10px",
            borderTop: `2px solid ${COLORS.PRIMARY.MAIN}`,
            width: "100%",
            opacity: "0",
            visibility: "hidden",
            transform: "translateY(10px)",
            transition: "0.4s",
            zIndex: "1000",
          }}
        >
          <ul
            style={{
              listStyle: "none",
              padding: 0,
              margin: 0,
            }}
          >
            <li>
              <Button
                sx={{
                  bgcolor: globalState?.isDarkMode
                    ? COLORS.BLACK
                    : COLORS.PRIMARY.MAIN,
                  color: "white",
                  padding: "10px 20px",
                  marginBottom: "20px",
                  transition: "0.3s",
                  width: "100%",
                  "&:hover": {
                    bgcolor: COLORS.SECONDARY.MAIN,
                  },
                }}
                onClick={() => navigate("/my-details")}
              >
                {lang == "ar" ? "بيانات الحساب" : "Account details"}
              </Button>
            </li>
            <li>
              <Button
                onClick={openLogout}
                sx={{
                  bgcolor: globalState?.isDarkMode
                    ? COLORS.BLACK
                    : COLORS.PRIMARY.MAIN,
                  color: "white",
                  padding: "10px 20px",
                  marginBottom: "20px",
                  transition: "0.3s",
                  width: "100%",
                  "&:hover": {
                    bgcolor: COLORS.SECONDARY.MAIN,
                  },
                }}
              >
                {lang == "ar" ? "تسجيل الخروج" : "Logout"}
              </Button>
            </li>
            <li>
              <Button
                onClick={openDelAcc}
                sx={{
                  color: globalState?.isDarkMode ? COLORS.WHITE : COLORS.WHITE,
                  bgcolor: globalState?.isDarkMode
                    ? COLORS.BLACK
                    : COLORS.PRIMARY.MAIN,
                  padding: "10px 20px",
                  transition: "0.3s",
                  border: `2px solid ${COLORS.PRIMARY.MAIN}`,
                  width: "100%",
                  "&:hover": {
                    bgcolor: COLORS.SECONDARY.MAIN,
                    borderColor: COLORS.SECONDARY.MAIN,
                    color: "white",
                  },
                }}
              >
                {lang == "ar" ? "حذف الحساب" : "Delete account"}
              </Button>
            </li>
          </ul>
        </Box>
      </Box>
      <Logout open={logout} setOpen={setLogout} />
      <Remove open={remove} setOpen={setRemove} />
    </>
  );
};
