import {
  Box,
  Container,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import useCustomQuery from "@/hooks/useCustomQuery";
import { HashLoader } from "react-spinners";
import { COLORS } from "@/constants/Colors/colors";
import { useSelector } from "react-redux";
function MyStats() {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const { data: stats, isPending: isLoadingStats } = useCustomQuery({
    key: ["stats", lang],
    url: `stats`,
    lang,
    select: (data) => data,
  });
  return (
    <>
      <Container maxWidth="2xl">
        {isLoadingStats ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: "30px",
              marginBottom: "30px",
            }}
          >
            <HashLoader color={COLORS.PRIMARY.MAIN} />
          </div>
        ) : (
          <>
            <Box my={5}>
              <Typography fontSize={18} fontFamily={"Noor-bold"}>
                <span
                  style={{
                    textShadow: `3px 3px 2px ${COLORS.PRIMARY.MAIN}`,
                    fontSize: "50px",
                  }}
                >
                  {lang == "ar" ? "الدروس" : "Lessons"}
                </span>
              </Typography>
            </Box>

            {stats?.data?.data.length > 0 ? (
              <Box>
                {stats?.data?.data.map(
                  ({ name, description, progress_percentage }, index) => {
                    return (
                      <ListItem
                        key={index}
                        sx={{
                          background: "#2A2A28",
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "15px 20px",
                          borderRadius: "10px",
                          textAlign: "right",
                          marginBottom: "20px",
                        }}
                      >
                        <ListItemText sx={{}}>
                          <Typography color={COLORS.WHITE} fontSize={30} mb={1}>
                            {name}
                          </Typography>
                          <Typography
                            sx={{ fontWeight: "bold" }}
                            fontSize={18}
                            color={COLORS.PRIMARY.MAIN}
                          >
                            {description}
                          </Typography>
                        </ListItemText>

                        <Typography
                          fontSize={36}
                          sx={{
                            textShadow: "3px 3px 2px #2f8bc8",
                          }}
                          color={COLORS.WHITE}
                        >
                          {progress_percentage} %
                        </Typography>
                      </ListItem>
                    );
                  }
                )}
              </Box>
            ) : (
              <Container maxWidth="xl">
                <Typography sx={{ fontFamily: "Noor-bold" }}>
                  {lang == "ar" ? "لا توجد بيانات" : "No Data"}
                </Typography>
              </Container>
            )}
            <Box my={5}>
              <Typography
                fontSize={18}
                fontFamily={"Noor-bold"}
                // color={COLORS.DARK_GRAY}
              >
                <span
                  style={{
                    textShadow: "3px 3px 2px #2f8bc8",
                    fontSize: "50px",
                  }}
                >
                  {lang == "ar" ? "الامتحانات" : "Exams"}
                </span>
              </Typography>
            </Box>
            {stats?.data?.exams?.filter((x) => x.exam.type === "Exam").length >
            0 ? (
              <Box>
                {stats?.data?.exams
                  ?.filter((x) => x.exam.type === "Exam")
                  .map(
                    ({ user_grade, status, exam: { title, grade } }, index) => {
                      return (
                        <ListItem
                          key={index}
                          sx={{
                            background: "#2A2A28",
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "15px 20px",
                            borderRadius: "10px",
                            textAlign: "right",
                            marginBottom: "20px",
                          }}
                        >
                          <ListItemText sx={{}}>
                            <Typography
                              color={COLORS.WHITE}
                              fontSize={30}
                              mb={1}
                            >
                              {title}
                            </Typography>
                            <Typography
                              sx={{ fontWeight: "bold", width: "60px" }}
                              px={1}
                              py={1}
                              textAlign={"center"}
                              borderRadius={"10px"}
                              fontSize={14}
                              color={"white"}
                              backgroundColor={
                                status === "Success" ? "#2daa48" : "#e70808"
                              }
                            >
                              {status === "Success"
                                ? lang == "ar"
                                  ? "نجاح"
                                  : "success"
                                : lang == "ar"
                                ? "فشل"
                                : "Fail"}
                            </Typography>
                          </ListItemText>

                          <Typography
                            fontSize={36}
                            color={COLORS.WHITE}
                            sx={{
                              textShadow: "3px 3px 2px #2f8bc8",
                            }}
                          >
                            {grade || "0"} / {user_grade || "0"}
                          </Typography>
                        </ListItem>
                      );
                    }
                  )}
              </Box>
            ) : (
              <Container maxWidth="xl">
                <Typography sx={{ fontFamily: "Noor-bold" }}>
                  {lang == "ar" ? "لا توجد بيانات" : "No Data"}
                </Typography>
              </Container>
            )}

            <Box my={5}>
              <Typography
                fontSize={18}
                fontFamily={"Noor-bold"}
                // color={COLORS.DARK_GRAY}
              >
                <span
                  style={{
                    textShadow: "3px 3px 2px #2f8bc8",
                    fontSize: "50px",
                  }}
                >
                  {lang == "ar" ? "الواجبات" : "Homework"}
                </span>
              </Typography>
            </Box>
            {stats?.data?.exams.length > 0 ? (
              <Box>
                {stats?.data?.exams
                  ?.filter((x) => x.exam.type === "HomeWork")
                  .map(
                    ({ user_grade, status, exam: { title, grade } }, index) => {
                      return (
                        <ListItem
                          key={index}
                          sx={{
                            background: "#2A2A28",
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "15px 20px",
                            borderRadius: "10px",
                            textAlign: "right",
                            marginBottom: "20px",
                          }}
                        >
                          <ListItemText sx={{}}>
                            <Typography
                              color={COLORS.WHITE}
                              fontSize={30}
                              mb={1}
                            >
                              {title}
                            </Typography>
                            <Typography
                              sx={{ fontWeight: "bold", width: "60px" }}
                              px={1}
                              py={1}
                              textAlign={"center"}
                              borderRadius={"10px"}
                              fontSize={14}
                              color={"white"}
                              backgroundColor={
                                status === "Success" ? "#2daa48" : "#e70808"
                              }
                            >
                              {status === "Success"
                                ? lang == "ar"
                                  ? "نجاح"
                                  : "success"
                                : lang == "ar"
                                ? "فشل"
                                : "Fail"}
                            </Typography>
                          </ListItemText>

                          <Typography
                            color={COLORS.WHITE}
                            fontSize={36}
                            sx={{
                              textShadow: "3px 3px 2px #2f8bc8",
                            }}
                          >
                            {grade} / {user_grade}
                          </Typography>
                        </ListItem>
                      );
                    }
                  )}
              </Box>
            ) : (
              <Container maxWidth="xl">
                <Typography sx={{ fontFamily: "Noor-bold" }}>
                  {lang == "ar" ? "لا توجد بيانات" : "No Data"}
                </Typography>
              </Container>
            )}
          </>
        )}
      </Container>
    </>
  );
}

export default MyStats;
