export const COLORS = {
  DARK_GRAY: "#2A2A28",
  LIGHT_GRAY: "#EFEFEF",
  WHITE: "#FFFFFF",
  BLACK: "#000000",
  SOFT_DARK: "#333333",
  OFF_WHITE: "#F5F5F5",
  PRIMARY: { MAIN: "#2F8BC8", LIGHT: "#CCE6FF" },
  SECONDARY: { MAIN: "#22A770" },
  TERTIARY: { MAIN: "#E41376" },
  SUCCESS: "#80eead",
  DANGER: "#db3a3c",
  BOX: "#d1d1d1",
  ORANGE: "#F25234",
  GREEN: "#2DCC70",
  LIGHT_BLUE: "#00A3CC",
  SEA_GREEN: "#8FBC8F",
  VIOLET: "#C744C7",
  GOLD: "#FFD700",
  FOREST_GREEN: "#388E3C",
  PURPLE: "#9D38BD",
  GRAY: "#7F8C8D",
  LIGHT_PINK: "#F5B6C3",
  BLUE: "#007FFF",
};
