import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

export const ColorButton = styled(Button)(() => ({
  backgroundColor: "#2F8BC8",
  color: "#fff",
  borderBottom: "black solid 2px",
  borderRadius: "10px",
  "&:hover": {
    backgroundColor: "#fff",
    color: "#2F8BC8",
  },
}));
