import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { COLORS } from "@/constants/Colors/colors";
import { BookReservationForm } from "./BookReservationForm/BookReservationForm";
import { useSelector } from "react-redux";

export const UserBookCard = (props) => {
  const [open, setOpen] = useState(false);
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;

  const {
    id,
    img,
    title,
    price,
    teacherName,
    isOpenForReservation,
    available_amount,
  } = props;
  return (
    <>
      <Box
        sx={{
          width: "95%",
          padding: "10px",
          bgcolor: globalState?.isDarkMode ? COLORS.DARK_GRAY : COLORS.WHITE,
          borderRadius: "10px",
          marginBottom: "20px",
          position: "relative",
        }}
      >
        <Box
          sx={{
            height: "230px",
            borderRadius: "5px",
          }}
        >
          <img
            src={img}
            alt="title"
            style={{
              width: "100%",
              borderRadius: "5px",
              height: "100%",
            }}
          />
        </Box>

        <Box px={1} py={1}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              // color={"black"}
              fontSize={20}
              fontFamily={"Noor-bold"}
              mb={0.5}
            >
              {title?.length > 15 ? `${title?.slice(0, 15)}...` : title}
            </Typography>

            <Typography
              fontSize={14}
              fontFamily={"Noor-bold"}
              sx={{
                padding: "5px 10px",
                bgcolor: "primary.main",
                color: "white",
                borderRadius: "5px",
              }}
            >
              {price} {lang == "ar" ? "جنية" : "EGP"}
            </Typography>
          </Box>

          <Typography
            fontSize={14}
            sx={{
              opacity: "0.6",
            }}
            mb={2}
          >
            {lang == "ar" ? "الكمية المتاحة :" : "Available quantity"}{" "}
            {available_amount}
          </Typography>
          <Typography fontSize={18} color={"primary.main"} mb={1}>
            {teacherName}
          </Typography>

          <Button
            onClick={() => {
              setOpen(true);
            }}
            sx={{
              bgcolor: COLORS.PRIMARY.MAIN,
              color: COLORS.WHITE,
              width: "100%",
              "&:hover": { bgcolor: COLORS.PRIMARY.MAIN },
              "&:disabled": { bgcolor: COLORS.BOX, color: COLORS.DARK_GRAY },
            }}
            disabled={!isOpenForReservation}
          >
            {isOpenForReservation
              ? lang == "ar"
                ? "احجز الان"
                : "Book now"
              : lang == "ar"
              ? "غير متاح للحجز"
              : "Unavailable for booking"}
          </Button>
        </Box>
      </Box>
      <BookReservationForm open={open} setOpen={setOpen} id={id} book={props} />
    </>
  );
};
