import { COLORS } from "@/constants/Colors/colors";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import { Box, IconButton, Typography } from "@mui/material";
import ColorButton from "@shared/Buttons/ColorButton/ColorButton";
import useCustomMutation from "@/hooks/useCustomMutation";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { HashLoader } from "react-spinners";
import WalletBox from "./WalletBox";
import { useSelector } from "react-redux";

export const SubscribeBox = ({ setOpen, course }) => {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const close = () => setOpen(false);
  const [openWallet, setOpenWallet] = useState(false);
  const queryClient = useQueryClient();
  const [paymentCode, setPaymentCode] = useState("");
  const { mutate, isPending } = useCustomMutation({
    url: `subscription/useCode`,
    invalidate: ["mySubscription", "courses"],
    successMsg: lang == "ar" ? "تم الاشتراك بنجاح" : "Subscribe succefully",
    onSuccess: () => {
      queryClient.invalidateQueries(["mySubscription", "courses"]);
      close();
    },
  });

  const [payWithWallet, setPayWithWallet] = useState(false);
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "unset");
  });
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 1000,
        bgcolor: "#11111177",
        width: "100%",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        width={400}
        height={420}
        sx={{
          bgcolor: globalState?.isDarkMode ? COLORS.DARK_GRAY : COLORS.WHITE,
          borderRadius: "20px",
          position: "relative",
          flexDirection: "column",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 5,
        }}
      >
        <IconButton
          onClick={close}
          sx={{ position: "absolute", top: 5, right: 5 }}
        >
          <CloseIcon fontSize="medium" sx={{ color: COLORS.PRIMARY.MAIN }} />
        </IconButton>
        <Box
          sx={{
            flexDirection: "column",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 5,
            width: "100%",
          }}
        >
          <Typography sx={{ fontSize: 20, fontFamily: "Noor-bold" }}>
            {lang == "ar" ? "ادخل الكود" : "Enter Code"}
          </Typography>
          <input
            onChange={(e) => setPaymentCode(e.target.value)}
            type="text"
            style={{
              textAlign: "center",
              width: "50%",
              height: 40,
              padding: "0 5px",
              fontSize: 16,
              outlineColor: COLORS.PRIMARY.MAIN,
            }}
            placeholder={
              lang == "ar"
                ? "يرجي ادخال كود الاشتراك"
                : "Enter your code to subscribe"
            }
          />
          <IconButton
            disabled={isPending}
            onClick={() => mutate({ code: paymentCode })}
            sx={{
              bgcolor: COLORS.PRIMARY.MAIN,
              borderRadius: 1,
              paddingX: 5,
              paddingY: 1,
              color: COLORS.WHITE,
              "&:hover": {
                color: COLORS.PRIMARY.MAIN,
                backgroundColor: COLORS.WHITE,
              },
            }}
          >
            {isPending ? (
              <HashLoader size={20} color={COLORS.PRIMARY.MAIN} />
            ) : (
              <SendIcon fontSize="medium" />
            )}
          </IconButton>
        </Box>
        {course?.subscription_days !== 0 && (
          <Box
            sx={{
              flexDirection: "column",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 2,
              width: "100%",
            }}
          >
            <ColorButton
              sx={{
                fontSize: 15,
                fontFamily: "Noor-bold",
                width: "60%",
              }}
              onClick={() => setPayWithWallet(true)}
            >
              {lang == "ar" ? "اشترك عن طريق المحفظة" : "Subscribe via wallet"}
            </ColorButton>
          </Box>
        )}
      </Box>
      {payWithWallet && (
        <WalletBox
          setOpen={setPayWithWallet}
          open={payWithWallet}
          course={course}
        />
      )}
    </Box>
  );
};
