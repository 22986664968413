import { COLORS } from "@/constants/Colors/colors";
import {
  Button,
  Typography,
  TextField,
  InputAdornment,
  Box,
} from "@mui/material";
import Modal from "@shared/Modal/Modal";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { bookReservationScheme } from "./bookReservationScheme";
import useCustomMutation from "@/hooks/useCustomMutation";
import { HashLoader } from "react-spinners";
import { zodResolver } from "@hookform/resolvers/zod";
import { governorates } from "@/hooks/governorates";
import { ReservationImagePicker } from "./ReservationImagePicker";
import { useQueryClient } from "@tanstack/react-query";
import { notifySuccess } from "@shared/Toast/toast";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

export const BookReservationForm = ({ open, setOpen, id, book }) => {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const payment_method = [
    { value: "cash", label: lang == "ar" ? "كاش" : "Cash" },
    { value: "wallet", label: lang == "ar" ? "محفظة" : "Wallet" },
  ];
  const {
    handleSubmit,
    control,
    register,
    setError,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(bookReservationScheme),
  });
  const queryClient = useQueryClient();
  const { mutate, isPending } = useCustomMutation({
    url: `bookOrder/createOrder`,
    lang,
    onSuccess: () => {
      notifySuccess(
        lang == "ar" ? "تم الحجز بنجاح" : "Reserve a book succefully"
      );
      setOpen(false);
      queryClient.invalidateQueries(["my orders"]);
    },
  });
  console.log(errors, "errors");
  function submit(data) {
    console.log(data);
    const reqFormData = new FormData();
    reqFormData.append("name", data?.name);
    reqFormData.append("phone", data?.phone);
    reqFormData.append("secondary_phone", data?.secondary_phone);
    reqFormData.append("city", data?.city);
    reqFormData.append("address", data?.address);
    // reqFormData.append("town", data?.town);
    // reqFormData.append("state", data?.state);
    // reqFormData.append("station", data?.station);
    // reqFormData.append("telegram_username", "@test");
    // reqFormData.append("building_number", data?.building_number);
    // reqFormData.append("reservation_phone", data?.reservation_phone);
    // reqFormData.append("reservation_image", data?.reservation_image[0]);
    reqFormData.append("book_id", id);
    reqFormData.append(
      "reservation_date",
      dayjs(data?.reservation_date).format("YYYY-MM-DD hh:mm:ss A")
    );
    reqFormData.append("quantity", data?.quantity);
    reqFormData.append("payment_method", data?.payment_method);
    mutate(reqFormData);
  }
  return (
    <Modal open={open} closeFn={() => setOpen(false)}>
      <Typography sx={{ fontSize: 24, fontFamily: "Noor-bold" }}>
        {/* {lang == "ar" ? "حجز كتاب" : "Reserve a book"} */}
        <span style={{ color: COLORS.PRIMARY.MAIN }}>{book.title}</span>
      </Typography>
      <form
        style={{
          marginTop: 15,
          width: "60%",
          display: "flex",
          flexDirection: "column",
          gap: 15,
          alignItems: "center",
        }}
        onSubmit={handleSubmit(submit)}
      >
        <TextField
          label={lang == "ar" ? "الاسم رباعي" : "Full name"}
          fullWidth
          type="text"
          {...register("name")}
          error={errors?.name}
          helperText={errors?.name?.message}
          sx={{
            "& .MuiFormLabel-root.Mui-focused": {
              color: "#2f8bc8",
            },
            "& label": {
              left: lang == "ar" ? "unset" : "0.25rem",
              right: lang == "ar" ? "1.75rem" : "unset",
              transformOrigin: lang == "ar" ? "right" : "left",
              fontSize: "0.8rem",
              // color: "#2f8bc8",
              color: globalState?.isDarkMode ? COLORS.WHITE : COLORS.BLACK,
            },
            "& legend": {
              textAlign: lang == "ar" ? "right" : "left",
              fontSize: "0.6rem",
            },
            direction: lang == "ar" ? "rtl" : "ltr",
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment
                position={lang == "ar" ? "end" : "start"}
              ></InputAdornment>
            ),
          }}
        />
        <TextField
          label={lang == "ar" ? "رقم هاتف متاح" : "Available phone number"}
          fullWidth
          type="phone"
          {...register("phone")}
          error={errors?.phone}
          helperText={errors?.phone?.message}
          sx={{
            "& .MuiFormLabel-root.Mui-focused": {
              color: "#2f8bc8",
            },
            "& label": {
              left: lang == "ar" ? "unset" : "0.25rem",
              right: lang == "ar" ? "1.75rem" : "unset",
              transformOrigin: lang == "ar" ? "right" : "left",
              fontSize: "0.8rem",
              // color: "#2f8bc8",
              color: globalState?.isDarkMode ? COLORS.WHITE : COLORS.BLACK,
            },
            "& legend": {
              textAlign: lang == "ar" ? "right" : "left",
              fontSize: "0.6rem",
            },
            direction: lang == "ar" ? "rtl" : "ltr",
          }}
          InputProps={{
            startAdornment: <InputAdornment position="end"></InputAdornment>,
          }}
        />
        <TextField
          label={lang == "ar" ? "رقم هاتف احتياطي" : "Secondary phone number"}
          fullWidth
          type="phone"
          {...register("secondary_phone")}
          error={errors?.secondary_phone}
          helperText={errors?.secondary_phone?.message}
          sx={{
            "& .MuiFormLabel-root.Mui-focused": {
              color: "#2f8bc8",
            },
            "& label": {
              left: lang == "ar" ? "unset" : "0.25rem",
              right: lang == "ar" ? "1.75rem" : "unset",
              transformOrigin: lang == "ar" ? "right" : "left",
              fontSize: "0.8rem",
              // color: "#2f8bc8",
              color: globalState?.isDarkMode ? COLORS.WHITE : COLORS.BLACK,
            },
            "& legend": {
              textAlign: lang == "ar" ? "right" : "left",
              fontSize: "0.6rem",
            },
            direction: lang == "ar" ? "rtl" : "ltr",
          }}
          InputProps={{
            startAdornment: <InputAdornment position="end"></InputAdornment>,
          }}
        />
        <Box
          sx={{
            position: "relative",
            width: "100%",
            // marginRight: "10px",
          }}
        >
          <span
            style={{
              position: "absolute",
              top: "-11px",
              right: lang == "ar" ? "10px" : "unset",
              left: lang == "ar" ? "unset" : "10px",
              fontSize: "12px",
              padding: "0 5px",
              color: globalState?.isDarkMode ? COLORS.WHITE : COLORS.BLACK,
              background: globalState?.isDarkMode
                ? COLORS.DARK_GRAY
                : COLORS.WHITE,
            }}
          >
            {lang == "ar" ? "المحافظة" : "City"}
          </span>

          <select
            name=""
            id=""
            placeholder={lang == "ar" ? "المحافظة" : "City"}
            style={{
              background: globalState?.isDarkMode
                ? "transparent"
                : COLORS.WHITE,
              color: globalState?.isDarkMode
                ? COLORS.PRIMARY.MAIN
                : COLORS.BLACK,
              border: "1px solid #ccc",
              padding: "20px 15px",
              borderRadius: "5px",
              outline: "none",
              fontSize: "16px",
              width: "100%",
              transition: "0.4s",
              direction: lang == "ar" ? "rtl" : "ltr",
            }}
            {...register("city", {
              required: lang == "ar" ? `يجب اختيار المحافظة` : "Choose a city",
              validate: () =>
                (selectedCity != lang) == "ar"
                  ? "اختر المحافظة"
                  : "Choose a city" || lang == "ar"
                  ? "يجب اختيار المحافظة"
                  : "Choose a city",
            })}
          >
            <option value="" disabled selected>
              {lang == "ar" ? "اختر المحافظة" : "Choose a city"}
            </option>
            {governorates?.map((i, index) => {
              return (
                <>
                  <option key={index} value={i.value}>
                    {i.label}
                  </option>
                </>
              );
            })}
          </select>
          {errors.city ? (
            <Typography
              fontSize={12}
              //   fontFamily={"Noor-bold"}
              color={COLORS.DANGER}
              mt={1}
              mr={1}
            >
              {errors.city?.message}
            </Typography>
          ) : (
            ""
          )}
        </Box>
        <TextField
          label={lang == "ar" ? "العنوان" : "address"}
          fullWidth
          type="text"
          {...register("address")}
          error={errors?.address}
          helperText={errors?.address?.message}
          sx={{
            "& .MuiFormLabel-root.Mui-focused": {
              color: "#2f8bc8",
            },
            "& label": {
              left: lang == "ar" ? "unset" : "0.25rem",
              right: lang == "ar" ? "1.75rem" : "unset",
              transformOrigin: lang == "ar" ? "right" : "left",
              fontSize: "0.8rem",
              // color: "#2f8bc8",
              color: globalState?.isDarkMode ? COLORS.WHITE : COLORS.BLACK,
            },
            "& legend": {
              textAlign: lang == "ar" ? "right" : "left",
              fontSize: "0.6rem",
            },
            direction: lang == "ar" ? "rtl" : "ltr",
          }}
          InputProps={{
            startAdornment: <InputAdornment position="end"></InputAdornment>,
          }}
        />
        {/* <Box sx={{ width: "100%", display: "flex", gap: 2 }}>
          <TextField
            label="حي"
            fullWidth
            type="text"
            {...register("state")}
            error={errors?.state}
            helperText={errors?.state?.message}
            sx={{
              "& .MuiFormLabel-root.Mui-focused": {
                color: "#2f8bc8",
              },
              "& label": {
                left: "unset",
                right: "1.75rem",
                transformOrigin: "right",
                fontSize: "0.8rem",
                color: "#2f8bc8",
              },
              "& legend": {
                textAlign: "right",
                fontSize: "0.6rem",
              },
              direction: "rtl",
            }}
            InputProps={{
              startAdornment: <InputAdornment position="end"></InputAdornment>,
            }}
          />
          <TextField
            label="مركز"
            fullWidth
            type="text"
            {...register("station")}
            error={errors?.station}
            helperText={errors?.station?.message}
            sx={{
              "& .MuiFormLabel-root.Mui-focused": {
                color: "#2f8bc8",
              },
              "& label": {
                left: "unset",
                right: "1.75rem",
                transformOrigin: "right",
                fontSize: "0.8rem",
                color: "#2f8bc8",
              },
              "& legend": {
                textAlign: "right",
                fontSize: "0.6rem",
              },
              direction: "rtl",
            }}
            InputProps={{
              startAdornment: <InputAdornment position="end"></InputAdornment>,
            }}
          />
        </Box> */}
        {/* <TextField
          label="الشارع"
          fullWidth
          type="text"
          {...register("street")}
          error={errors?.street}
          helperText={errors?.street?.message}
          sx={{
            "& .MuiFormLabel-root.Mui-focused": {
              color: "#2f8bc8",
            },
            "& label": {
              left: "unset",
              right: "1.75rem",
              transformOrigin: "right",
              fontSize: "0.8rem",
              color: "#2f8bc8",
            },
            "& legend": {
              textAlign: "right",
              fontSize: "0.6rem",
            },
            direction: "rtl",
          }}
          InputProps={{
            startAdornment: <InputAdornment position="end"></InputAdornment>,
          }}
        />
        <TextField
          label="رقم العقار"
          fullWidth
          type="text"
          {...register("building_number")}
          error={errors?.building_number}
          helperText={errors?.building_number?.message}
          sx={{
            "& .MuiFormLabel-root.Mui-focused": {
              color: "#2f8bc8",
            },
            "& label": {
              left: "unset",
              right: "1.75rem",
              transformOrigin: "right",
              fontSize: "0.8rem",
              color: "#2f8bc8",
            },
            "& legend": {
              textAlign: "right",
              fontSize: "0.6rem",
            },
            direction: "rtl",
          }}
          InputProps={{
            startAdornment: <InputAdornment position="end"></InputAdornment>,
          }}
        />
        <TextField
          label="رقم هاتف الحجز (اللذي تم تحويل المبلغ منه)"
          fullWidth
          type="phone"
          {...register("reservation_phone")}
          error={errors?.reservation_phone}
          helperText={errors?.reservation_phone?.message}
          sx={{
            "& .MuiFormLabel-root.Mui-focused": {
              color: "#2f8bc8",
            },
            "& label": {
              left: "unset",
              right: "1.75rem",
              transformOrigin: "right",
              fontSize: "0.8rem",
              color: "#2f8bc8",
            },
            "& legend": {
              textAlign: "right",
              fontSize: "0.6rem",
            },
            direction: "rtl",
          }}
          InputProps={{
            startAdornment: <InputAdornment position="end"></InputAdornment>,
          }}
        /> */}
        <TextField
          type="datetime-local"
          label={lang == "ar" ? "تاريخ الحجز" : "Reservation date"}
          fullWidth
          {...register("reservation_date")}
          error={errors?.reservation_date}
          helperText={errors?.reservation_date?.message}
          sx={{
            "& .MuiFormLabel-root.Mui-focused": {
              color: "#2f8bc8",
            },
            "& label": {
              left: lang == "ar" ? "unset" : "0.25rem",
              right: lang == "ar" ? "1.75rem" : "unset",
              transformOrigin: lang == "ar" ? "right" : "left",
              fontSize: "0.8rem",
              // color: "#2f8bc8",
              color: globalState?.isDarkMode ? COLORS.WHITE : COLORS.BLACK,
            },
            "& legend": {
              textAlign: lang == "ar" ? "right" : "left",
              fontSize: "0.6rem",
            },
            direction: lang == "ar" ? "rtl" : "ltr",
          }}
          InputProps={{
            startAdornment: <InputAdornment position="end"></InputAdornment>,
          }}
        />
        <TextField
          label={lang == "ar" ? "الكمية" : "Quantity"}
          fullWidth
          type="number"
          //   onChange={(e) => {
          //     setValue("quantity", e.target.value);
          //   }}
          {...register("quantity")}
          error={errors?.quantity}
          helperText={errors?.quantity?.message}
          sx={{
            "& .MuiFormLabel-root.Mui-focused": {
              color: "#2f8bc8",
            },
            "& label": {
              left: lang == "ar" ? "unset" : "0.25rem",
              right: lang == "ar" ? "1.75rem" : "unset",
              transformOrigin: lang == "ar" ? "right" : "left",
              fontSize: "0.8rem",
              // color: "#2f8bc8",
              color: globalState?.isDarkMode ? COLORS.WHITE : COLORS.BLACK,
            },
            "& legend": {
              textAlign: lang == "ar" ? "right" : "left",
              fontSize: "0.6rem",
            },
            direction: lang == "ar" ? "rtl" : "ltr",
          }}
          InputProps={{
            startAdornment: <InputAdornment position="end"></InputAdornment>,
          }}
        />
        <Box
          sx={{
            position: "relative",
            width: "100%",
            // marginRight: "10px",
          }}
        >
          <span
            style={{
              position: "absolute",
              top: "-11px",
              right: "10px",
              fontSize: "12px",
              color: globalState?.isDarkMode ? COLORS.WHITE : COLORS.BLACK,
              background: globalState?.isDarkMode
                ? COLORS.DARK_GRAY
                : COLORS.WHITE,
              padding: "0 5px",
            }}
          >
            {lang == "ar" ? "طرق الدفع" : "payment methods"}
          </span>

          <select
            name=""
            id=""
            placeholder={lang == "ar" ? "طريقة الدفع" : "payment methods"}
            style={{
              background: globalState?.isDarkMode
                ? "transparent"
                : COLORS.WHITE,
              border: "1px solid #ccc",
              padding: "20px 15px",
              borderRadius: "5px",
              outline: "none",
              fontSize: "16px",
              width: "100%",
              color: globalState?.isDarkMode
                ? COLORS.PRIMARY.MAIN
                : COLORS.BLACK,
              transition: "0.4s",
              direction: lang == "ar" ? "rtl" : "ltr",
            }}
            {...register("payment_method", {
              required:
                lang == "ar" ? `يجب اختيار طريقة دفع` : "Choose payment method",
              validate: () =>
                (selectedCity != lang) == "ar"
                  ? "اختر طريقة دفع"
                  : "Choose payment method" || lang == "ar"
                  ? `يجب اختيار طريقة دفع`
                  : "Choose payment method",
            })}
          >
            <option value="" disabled selected>
              {lang == "ar" ? "اختر طريقة الدفع" : "Choose payment method"}
            </option>
            {payment_method?.map((i, index) => {
              return (
                <>
                  <option key={index} value={i.value}>
                    {i.label}
                  </option>
                </>
              );
            })}
          </select>
          {errors.payment_method ? (
            <Typography
              fontSize={12}
              //   fontFamily={"Noor-bold"}
              color={COLORS.DANGER}
              mt={1}
              mr={1}
            >
              {errors.payment_method?.message}
            </Typography>
          ) : (
            ""
          )}
        </Box>

        <Button
          type="submit"
          sx={{
            bgcolor: COLORS.PRIMARY.MAIN,
            color: COLORS.WHITE,
            "&:hover": { bgcolor: COLORS.PRIMARY.MAIN },
          }}
        >
          {isPending ? (
            <HashLoader size={20} color={COLORS.WHITE} />
          ) : lang == "ar" ? (
            "احجز الان"
          ) : (
            "Book now"
          )}
        </Button>
      </form>
    </Modal>
  );
};
