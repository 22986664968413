import React from "react";
import { NavBar } from "../NavBar/NavBar";
import { Box } from "@mui/material";
import { UserSubsContextProvider } from "../Context/UserSubsContext";
import { AllCoursesContextProvider } from "../Context/AllCoursesContext";
import Footer from "@/modules/home/components/Footer/Footer";

export const UserHome = ({ child: Child }) => {
  return (
    <Box sx={{ width: "100%" }}>
      <NavBar />
      <AllCoursesContextProvider>
        <UserSubsContextProvider>
          <Child />
        </UserSubsContextProvider>
      </AllCoursesContextProvider>
      <Footer />
    </Box>
  );
};
