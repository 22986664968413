import { Box, Typography } from "@mui/material";
import loggedinHero from "@assets/images/cuate.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import useCustomQuery from "@/hooks/useCustomQuery";

export const Hero = () => {
  const { data } = useCustomQuery({
    key: ["bar"],
    url: "bar/getAll",
    select: (data) => data,
  });
  return (
    <>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        gap={6}
        py={10}
        px={10}
        height={300}
        sx={{
          backgroundColor: "#2f8bc8",
        }}
      >
        <Box
          sx={{
            width: "50%",
            // border: "10px solid red",
          }}
        >
          <Slider
            autoplay
            infinite
            speed={1000}
            slidesToShow={1}
            slidesToScroll={1}
            arrows={false}
          >
            {data?.data?.data?.length >= 2 &&
              data?.data?.data?.map((item, i) => {
                return (
                  <Typography
                    key={item.id}
                    fontSize={80}
                    sx={{
                      color: "white",
                      fontFamily: "Noor-bold",
                      textShadow: `-3px 3px 0px #2a2a2a`,
                      textAlign: "center",
                    }}
                  >
                    {item?.body || ""}
                  </Typography>
                );
              })}
            {data?.data?.data?.length === 1 &&
              Array(2).fill(
                <Typography
                  key={data?.data?.data?.[0].id}
                  fontSize={80}
                  sx={{
                    color: "white",
                    fontFamily: "Noor-bold",
                    textShadow: `-3px 3px 0px #2a2a2a`,
                    textAlign: "center",
                  }}
                >
                  {data?.data?.data?.[0]?.body || ""}
                </Typography>
              )}
          </Slider>
        </Box>

        <Box
          width={"35%"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <img
            style={{
              objectFit: "cover",
              width: "100%",
              // border: "10px solid red",
              marginBottom: "40px",
              // marginLeft: "50px",
            }}
            src={loggedinHero}
            alt=""
          />
        </Box>
      </Box>
    </>
  );
};
