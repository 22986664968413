import { COLORS } from "@/constants/Colors/colors";
import { Box, Button, Typography } from "@mui/material";
import React from "react";
import WalletIcon from "@mui/icons-material/Wallet";
import Modal from "@shared/Modal/Modal";
import useCustomQuery from "@/hooks/useCustomQuery";
import { HashLoader } from "react-spinners";
import { useSelector } from "react-redux";
import PaymentMethods from "./PaymentMethods";

export default function UserWallet({ open, setOpen }) {
  const lang = useSelector((state) => state?.global?.lang);
  const { data, isPending } = useCustomQuery({
    url: `users/getCurrent`,
    key: ["current user", lang],
  });
  return (
    <>
      <Modal maxWidth={"sm"} open={open} closeFn={() => setOpen(false)}zIndex={500}>
        <Box
          sx={{
            display: "flex",
            marginY: 2,
            gap: 2,
            width: "100%",
            justifyContent: "center",
          }}
        >
          {isPending ? (
            <HashLoader size={20} color={COLORS.WHITE} />
          ) : (
            <Box>
              <Typography
                sx={{
                  fontSize: 30,
                  fontFamily: "Noor-bold",
                  textAlign: "center",
                }}
              >
                {`${
                  lang == "ar"
                    ? `رصيدك :`
                    : `Your Balance is :  ${data?.data?.money}`
                }`}
              </Typography>

              <PaymentMethods />
            </Box>
          )}
        </Box>
      </Modal>
    </>
  );
}
