import { z } from "zod";

export const updateCourseSchema = z.object({
  name: z.string().min(1, " اسم الكورس مطلوب"),
  description: z.string().min(1, " وصف الكورس مطلوب"),
  price: z.coerce.number().min(1, " السعر مطلوب"),
  duration: z.coerce.string().min(1, " مدة الكورس مطلوبة"),
  image: z.array(z.instanceof(File)).optional(),
  subscription_days: z.coerce
    .number()
    .transform((val) => {
      return parseInt(val);
    })
    .refine((val) => Number(val) > 0, {
      message: "ايام الاشتراك يجب ان تكون اكبر من الصفر",
    })
    .optional(),
});
