import { ThemeProvider, TextField } from "@mui/material";

export function SharedTextField({
  register,
  label,
  placeholder,
  extra,
  sx,
  dark,
}) {
  const lightStyle = {
    WebkitBoxShadow: "0 0 0 1000px white inset",
    WebkitTextFillColor: "#9747FF",
  };
  return (
    // <ThemeProvider>
    <TextField
      {...extra}
      {...register}
      placeholder={placeholder}
      label={label}
      size="small"
      focused
      inputProps={{
        style: dark ? {} : lightStyle,
        min: extra?.min,
      }}
      sx={{
        "& .MuiFormLabel-root.Mui-focused": {
          color: "#22a770",
        },
        "& label": {
          left: "unset",
          right: "1.75rem",
          transformOrigin: "right",
          fontSize: "0.8rem",
          color: "#22a770",
        },
        "& legend": {
          textAlign: "right",
          fontSize: "0.6rem",
        },
        color: "#ffffff",
        direction: "rtl",
        ...sx,
      }}
      InputProps={
        extra?.InputProps
          ? extra.InputProps
          : {
              style: {
                color: sx?.color ?? "#ffffff",
              },
              placeholderStyle: {
                color: "#ffffff",
              },
            }
      }
    />
    // </ThemeProvider>
  );
}
