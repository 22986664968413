import React, { useState } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import { COLORS } from "@/constants/Colors/colors";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import DeleteDialog from "../../DeleteDialog";
import { ExamEditor } from "../../../ExamEditor/ExamEditor";
import ExamStudentsData from "./../ExamStudentData";
import { useSelector } from "react-redux";

export const ModuleExam = ({ exam, module }) => {
  const language = useSelector((state) => state?.global);
  const lang = language?.lang;
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [deleteName, setDeleteName] = useState();
  const [confirmMessage, setConfirmMessage] = useState("");
  const handleDeleteClick = () => {
    setIsConfirmOpen(true);
    setConfirmMessage(
      lang == "ar"
        ? "هل انت متأكد من حذف الامتحان"
        : "Are you sure you want to delete the Exam?"
    );
    setDeleteData(exam);
    setDeleteName(exam?.title);
  };
  return (
    <Box
      sx={{
        width: "100%",
        paddingX: 2,
        color: COLORS.WHITE,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <AccessAlarmIcon sx={{ color: COLORS.PRIMARY.MAIN }} />
        <Typography>
          {exam?.title?.length > 20
            ? exam?.title?.slice(0, 20) + "..."
            : exam?.title}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", gap: 1 }}>
        <IconButton
          onClick={() => setEdit(true)}
          sx={{
            bgcolor: "#01c46e",
            "&:hover": { bgcolor: "#01c46e" },
            width: 28,
            height: 28,
          }}
        >
          <EditIcon fontSize="small" />
        </IconButton>
        <ExamEditor
          started={new Date() - new Date(exam?.starts_at) >= 0}
          examQuests={exam}
          open={edit}
          setOpen={setEdit}
          id={exam?.id}
          type={"Module"}
        />
        {new Date(exam?.starts_at) - new Date() > 0 && (
          <IconButton
            sx={{
              bgcolor: COLORS.DANGER,
              "&:hover": { bgcolor: COLORS.DANGER },
              width: 28,
              height: 28,
            }}
            onClick={handleDeleteClick}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        )}
        <DeleteDialog
          isConfirmOpen={isConfirmOpen}
          setIsConfirmOpen={setIsConfirmOpen}
          deleteName={deleteName}
          deleteData={deleteData}
          confirmMessage={confirmMessage}
        />
        <ExamStudentsData module={module} />
      </Box>
    </Box>
  );
};
