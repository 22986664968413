import React, { useState } from "react";
import { Box, Container, ListItem, Typography } from "@mui/material";
import videoPlayer from "@assets/images/play.png";
import book from "@assets/images/Book.png";
import link from "@assets/images/link.png";
import doc from "@assets/images/Document.png";
import { CourseVideo } from "./CourseVideo/CourseVideo";
import { CourseSummaries } from "./CourseSummaries/CourseSummaries";
import { CourseHomeWorkList } from "./CourseHomeWorkList/CourseHomeWorkList";
import { CourseExamsList } from "./CourseExamsList/CourseExamsList";
import { useParams } from "react-router-dom";
import useCustomQuery from "@/hooks/useCustomQuery";
import { api_url } from "@/constants/base_url";
import { COLORS } from "@/constants/Colors/colors";
import { HashLoader } from "react-spinners";
import { useSelector } from "react-redux";

export const Module = () => {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const [taps, setTaps] = useState("video");
  const { id, mid } = useParams();

  const {
    data: moduleDetailsData,
    isPending: isModuleDetailsLoading,
    isError: isModuleDetailsError,
  } = useCustomQuery({
    url: `${api_url}module?id=${mid}`,
    key: ["module", id, mid, lang],
    lang,
  });
  const { data: courseDetailsData } = useCustomQuery({
    url: `${api_url}course/${id}`,
    key: ["course", id, lang],
    lang,
  });
  const { data: homeworkData } = useCustomQuery({
    key: ["homeworkData", mid, lang],
    url: `${api_url}homeWork/getHomeWork?lesson_module_id=${mid}`,
    lang,
  });
  const { data: examData } = useCustomQuery({
    key: ["examData", mid, lang],
    url: `${api_url}exam/getExam?lesson_module_id=${mid}`,
    lang,
  });

  const content = (
    <Box
      color={"black"}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "start",
        padding: "50px 0px",
      }}
    >
      <Box width={"27%"}>
        <div
          style={{
            backgroundColor: COLORS.DARK_GRAY,
            borderRadius: "20px 20px 0px 0px",
          }}
        >
          <Typography
            fontFamily={"Noor-bold"}
            fontSize={20}
            sx={{
              bgcolor: COLORS.PRIMARY.MAIN,
              padding: "15px 20px",
              borderRadius: "20px 20px 0px 0px",
              color: "white",
            }}
          >
            {moduleDetailsData?.name}
          </Typography>

          <ul
            style={{
              listStyle: "none",
              margin: "0",
              padding: "0",
              color: "white",
            }}
          >
            <ListItem
              onClick={() => {
                setTaps("video");
              }}
              sx={{
                marginBottom: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "15px 20px",
                transition: "0.4s",
                cursor: "pointer",
                bgcolor: taps === "video" ? "#326cb36E" : "",
                borderRight:
                  taps === "video"
                    ? `7px solid ${COLORS.PRIMARY.MAIN}`
                    : `7px solid ${COLORS.DARK_GRAY}`,
                marginTop: "15px",
                "&:hover": {
                  bgcolor: "#326cb36E",
                  borderRight: `7px solid ${COLORS.PRIMARY.MAIN}`,
                },
              }}
            >
              <Typography>{lang == "ar" ? "الفيديوهات" : "Videos"}</Typography>
              <div
                style={{
                  background: COLORS.PRIMARY.MAIN,
                  padding: "6px 7px",
                  borderRadius: "50%",
                  display: "flex",
                }}
              >
                <img
                  src={videoPlayer}
                  alt=""
                  style={{
                    width: "15px",
                  }}
                />
              </div>
            </ListItem>
            <ListItem
              onClick={() => {
                setTaps("summaries");
              }}
              sx={{
                marginBottom: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "15px 20px",
                transition: "0.4s",
                bgcolor: taps === "summaries" ? "#326cb36E" : "",
                borderRight:
                  taps === "summaries"
                    ? `7px solid ${COLORS.PRIMARY.MAIN}`
                    : `7px solid ${COLORS.DARK_GRAY}`,
                cursor: "pointer",
                "&:hover": {
                  bgcolor: "#326cb36E",
                  borderRight: `7px solid ${COLORS.PRIMARY.MAIN}`,
                },
              }}
            >
              <Typography>{lang == "ar" ? "الملازم" : "Files"}</Typography>
              <div
                style={{
                  background: COLORS.PRIMARY.MAIN,
                  padding: "7px 7px",
                  borderRadius: "50%",
                  display: "flex",
                }}
              >
                <img
                  src={book}
                  alt=""
                  style={{
                    width: "16px",
                  }}
                />
              </div>
            </ListItem>
            <ListItem
              onClick={() => {
                setTaps("exams");
              }}
              sx={{
                marginBottom: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "15px 20px",
                transition: "0.4s",
                cursor: "pointer",
                bgcolor: taps === "exams" ? "#326cb36E" : "",
                borderRight:
                  taps === "exams"
                    ? `7px solid ${COLORS.PRIMARY.MAIN}`
                    : `7px solid ${COLORS.DARK_GRAY}`,
                "&:hover": {
                  bgcolor: "#326cb36E",
                  borderRight: `7px solid ${COLORS.PRIMARY.MAIN}`,
                },
              }}
            >
              <Typography>{lang == "ar" ? "الامتحان" : "Exam"}</Typography>
              <div
                style={{
                  background: COLORS.PRIMARY.MAIN,
                  padding: "6px 7px",
                  borderRadius: "50%",
                  display: "flex",
                }}
              >
                <img
                  src={link}
                  alt=""
                  style={{
                    width: "15px",
                  }}
                />
              </div>
            </ListItem>
            <ListItem
              onClick={() => {
                setTaps("home-work");
              }}
              sx={{
                marginBottom: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "15px 20px",
                transition: "0.4s",
                cursor: "pointer",
                bgcolor: taps === "home-work" ? "#326cb36E" : "",
                borderRight:
                  taps === "home-work"
                    ? `7px solid ${COLORS.PRIMARY.MAIN}`
                    : `7px solid ${COLORS.DARK_GRAY}`,
                "&:hover": {
                  bgcolor: "#326cb36E",
                  borderRight: `7px solid ${COLORS.PRIMARY.MAIN}`,
                },
              }}
            >
              <Typography>{lang == "ar" ? "الواجب" : "Homework"}</Typography>
              <div
                style={{
                  background: COLORS.PRIMARY.MAIN,
                  padding: "5px 7px",
                  borderRadius: "50%",
                  display: "flex",
                }}
              >
                <img
                  src={doc}
                  alt=""
                  style={{
                    width: "14px",
                  }}
                />
              </div>
            </ListItem>
          </ul>
        </div>

        <div
          style={{
            textAlign: "center",
            marginTop: "15px",
            color: globalState?.isDarkMode ? COLORS.WHITE : COLORS.BLACK,
          }}
        >
          <Typography fontFamily={"Noor-bold"} fontSize={22}>
            {lang == "ar" ? "كورس" : "Course"} {courseDetailsData?.name}
          </Typography>
        </div>
      </Box>
      <Box bgcolor={""} width={"70%"} sx={{ overflow: "hidden" }}>
        {taps === "video" && (
          <CourseVideo
            videos={moduleDetailsData?.lessons}
            videoApi={moduleDetailsData?.lesson_api_link}
          />
        )}
        {taps === "summaries" && <CourseSummaries />}
        {taps === "exams" && (
          <>
            {!examData?.data ? (
              <Typography
                fontSize={32}
                fontFamily={"Noor-bold"}
                textAlign={"center"}
                sx={{
                  color: globalState?.isDarkMode
                    ? COLORS.WHITE
                    : COLORS.DARK_GRAY,
                }}
              >
                {lang == "ar" ? "لا يوجد امتحان" : "There is no exam"}
              </Typography>
            ) : (
              <CourseExamsList
                // setExamId={setExamId}
                examId={examData?.data?.id}
                // startFunc={startFunc}
                exam={examData}
              />
            )}
          </>
        )}
        {console.log(homeworkData)}
        {taps === "home-work" &&
          (homeworkData?.data ? (
            <CourseHomeWorkList homework={homeworkData} />
          ) : (
            <Typography
              fontSize={32}
              fontFamily={"Noor-bold"}
              textAlign={"center"}
              sx={{
                color: globalState?.isDarkMode
                  ? COLORS.WHITE
                  : COLORS.DARK_GRAY,
              }}
            >
              {lang == "ar" ? "لا يوجد واجب" : "There is no homework"}
            </Typography>
          ))}
      </Box>
    </Box>
  );

  return (
    <>
      <Container maxWidth="2xl" sx={{ minHeight: "84vh" }}>
        {isModuleDetailsLoading ? (
          <Box
            sx={{
              width: "100%",
              height: "84vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <HashLoader color={COLORS.PRIMARY.MAIN} />
          </Box>
        ) : (
          content
        )}
      </Container>
    </>
  );
};
