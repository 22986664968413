import { COLORS } from "@/constants/Colors/colors";
import useCustomQuery from "@/hooks/useCustomQuery";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { HashLoader } from "react-spinners";
import { Box, Typography } from "@mui/material";
import { VideoPlayer } from "./VideoPlayer/VideoPlayer";
import { useSelector } from "react-redux";

export const CourseVideo = ({ videos, videoApi }) => {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const [lessonId, setLessonId] = useState(videos?.[0]?.id || 0);
  const { id, mid } = useParams();
  const {
    data: lesson,
    isPending: isLessonLoading,
    isStale,
  } = useCustomQuery({
    url: videoApi,
    data: { lesson_id: lessonId },
    gcTime: 0,
    staleTime: 0,
    key: ["lesson", id, mid, lessonId, lang],
    lang,
    enabled: !!mid && lessonId !== 0,
    method: "post",
  });

  const spinner = (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "30px",
        marginBottom: "30px",
      }}
    >
      <HashLoader color={COLORS.PRIMARY.MAIN} />
    </div>
  );

  const content = (
    <>
      <Box
        sx={{
          marginInline: "20px",
          flexDirection: "column",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          gap: 4,
          backgroundColor: globalState?.isDarkMode
            ? COLORS.DARK_GRAY
            : COLORS.WHITE,
          marginTop: "50px",
          // paddingY: "20px",
          // bgcolor:"red"
        }}
      >
        {videos?.length > 0 &&
          videos?.map(({ name, id }) => (
            <Box
              onClick={() => {
                setLessonId(id);
              }}
              key={id}
              sx={{
                width: "100%",
                boxShadow:
                  id == lessonId
                    ? `0px 6px 0px white, 0px 12px 0px ${COLORS.PRIMARY.MAIN}`
                    : "",
                borderRadius: 2,
                padding: 2,
                backgroundColor: COLORS.OFF_WHITE,
                cursor: "pointer",
                transition: "0.4s",
                bgcolor: COLORS.DARK_GRAY,
                color: COLORS.WHITE,
                //hover
                "&:hover": {
                  backgroundColor: "#326cb36E",
                  color: globalState?.isDarkMode
                    ? COLORS.WHITE
                    : COLORS.DARK_GRAY,
                },
              }}
            >
              <Typography>{name}</Typography>
            </Box>
          ))}
      </Box>
    </>
  );
  return (
    <>
      <Box sx={{ width: "100%", minHeight: "50vh" }}>
        {isLessonLoading ? (
          spinner
        ) : videos?.length > 0 ? (
          <VideoPlayer
            VideoLink={
              lesson?.data?.encrypted?.[lesson?.data?.encrypted.length - 1]?.url
            }
          />
        ) : (
          <Typography
            fontSize={24}
            fontFamily={"Noor-bold"}
            textAlign={"center"}
          >
            {lang == "ar" ? "لا يوجد فيديوهات" : "There is no videos"}
          </Typography>
        )}
      </Box>
      {content}
    </>
  );
};
