import { Box, Typography, Button } from "@mui/material";
import React from "react";
import { COLORS } from "@/constants/Colors/colors";
import { notifyError } from "@shared/Toast/toast";
import { useNavigate } from "react-router-dom";
import { UserCourseModule } from "./UserCourseModule/UserCourseModule";
import { useSelector } from "react-redux";

export const UserCourseModules = ({ bought, course }) => {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const navigate = useNavigate();
  return (
    <Box sx={{ padding: 5, width: "100%" }}>
      <Typography
        sx={{
          textAlign: "center",
          fontSize: 40,
          fontFamily: "Noor-bold",
          color: globalState?.isDarkMode ? COLORS.WHITE : COLORS.DARK_GRAY,
          marginBottom: 5,
        }}
      >
        {lang == "ar" ? "الحصص" : "Lessons"}
      </Typography>
      <Box>
        {course?.modules?.length > 0 ? (
          course?.modules.map(({ name, id }, index) => (
            <UserCourseModule
              key={id}
              name={name}
              id={id}
              index={index}
              bought={bought}
            />
          ))
        ) : (
          <Typography
            fontFamily={"Noor-bold"}
            mr={2}
            fontSize={50}
            textAlign={"center"}
            color={COLORS.DARK_GRAY}
            //   sx={{ textShadow: "0px 2px 0px #2f8bc8" }}
          >
            {lang == "ar" ? "لا يوجد حصص" : "There is no Lessons"}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
