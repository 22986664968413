import { Box, Button, Typography } from "@mui/material";
import loginImage from "@assets/images/Exams-bro.png";
import logo from "@assets/images/old/logo.png";
import { COLORS } from "@/constants/Colors/colors";
import { FONTS } from "@/constants/Fonts/fonts";
import { useForm } from "react-hook-form";
import Column from "@/components/Layout/Column/Column";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { api_url } from "@/constants/base_url";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { RemoveRedEyeOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";

export default function Signup() {
  const governorates = [
    { value: "Alexandria", label: "الإسكندرية" },
    { value: "Aswan", label: "أسوان" },
    { value: "Asyut", label: "أسيوط" },
    { value: "Beheira", label: "البحيرة" },
    { value: "Beni Suef", label: "بني سويف" },
    { value: "Cairo", label: "القاهرة" },
    { value: "Dakahlia", label: "الدقهلية" },
    { value: "Damietta", label: "دمياط" },
    { value: "Faiyum", label: "الفيوم" },
    { value: "Gharbia", label: "الغربية" },
    { value: "Giza", label: "الجيزة" },
    { value: "Ismailia", label: "الإسماعيلية" },
    { value: "Kafr El Sheikh", label: "كفر الشيخ" },
    { value: "Luxor", label: "الأقصر" },
    { value: "Matrouh", label: "مطروح" },
    { value: "Minya", label: "المنيا" },
    { value: "Monufia", label: "المنوفية" },
    { value: "New Valley", label: "الوادي الجديد" },
    { value: "North Sinai", label: "شمال سيناء" },
    { value: "Port Said", label: "بورسعيد" },
    { value: "Qalyubia", label: "القليوبية" },
    { value: "Qena", label: "قنا" },
    { value: "Red Sea", label: "البحر الأحمر" },
    { value: "Sharqia", label: "الشرقية" },
    { value: "Sohag", label: "سوهاج" },
    { value: "South Sinai", label: "جنوب سيناء" },
  ];

  // ----------- functions ------------
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      parent_phone: "",
      city: "",
      password: "",
      password_confirmation: "",
    },
  });

  const togglePassword = () => {
    if (passType === "password") {
      setPassType("text");
      return;
    }
    setPassType("password");
  };

  const { mutate, isError, error, isPending } = useMutation({
    mutationFn: (data) => {
      return axios.post(`${api_url}register`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
    },
    onSuccess: (data) => {
      console.log(data);
      navigate("/login");
    },
  });
  const onSubmit = (data) => {
    mutate(data);
  };
  if (isError) {
    console.log("axios error", error);
  }

  // ----------- hooks ----------
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const navigate = useNavigate();
  const password = watch("password", "");
  const selectedCity = watch("city");
  const [passType, setPassType] = useState("password");

  //   const methods = useForm({
  //     resolver: zodResolver(signUpSchema),
  //   });

  //   const { handleSubmit, errors } = methods;
  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"space-around"}
        alignItems={"center"}
        position={"relative"}
        overflow={"hidden"}
        height={"100vh"}
      >
        <Box width={"100%"} position={"absolute"} top={"40px"} right={"60px"}>
          <img
            src={logo}
            alt="logo"
            style={{
              width: "15%",
              padding: "5px",
            }}
          />
        </Box>

        <img
          src={loginImage}
          style={{
            zIndex: 1,
            width: "35%",
            paddingTop: 150,
          }}
        />

        <Column spacing={"space-between"} width={"50%"} gap={4}>
          <Typography
            fontSize={50}
            textAlign={"center"}
            fontFamily={FONTS.REGULAR}
            color={globalState?.isDarkMode ? COLORS.WHITE : COLORS.DARK_GRAY}
          >
            {lang == "ar" ? "انشاء حساب" : "Create new account"}
          </Typography>

          {/* <FormProvider {...methods}> */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                // alignItems: 'center',
                marginBottom: "40px",
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  // marginLeft: "10px",
                }}
              >
                <span
                  style={{
                    position: "absolute",
                    top: "-24px",
                    right: lang == "ar" ? "40px" : "unset",
                    left: lang == "ar" ? "unset" : "40px",
                    fontSize: "15px",
                    color: globalState?.isDarkMode ? COLORS.WHITE : "#2f8bc8",
                    background: globalState?.isDarkMode
                      ? COLORS.DARK_GRAY
                      : "#F5F5F5",
                    padding: "10px 20px",
                  }}
                >
                  {lang == "ar" ? "الاسم بالكامل" : "Full name"}
                </span>
                <input
                  type="text"
                  placeholder={lang == "ar" ? "الاسم بالكامل" : "Full name"}
                  style={{
                    background: globalState?.isDarkMode
                      ? COLORS.DARK_GRAY
                      : "#F5F5F5",
                    border: "1px solid #2f8bc8",
                    padding: "20px 15px",
                    borderRadius: "5px",
                    outline: "none",
                    fontSize: "16px",
                    width: "100%",
                    color: globalState?.isDarkMode ? COLORS.WHITE : "black",
                    transition: "0.4s",
                    direction: lang == "ar" ? "rtl" : "ltr",
                  }}
                  {...register("name", {
                    required:
                      lang == "ar"
                        ? `يجب ادخال الاسم`
                        : `You must enter a name`,
                    validate: (name) => {
                      // Split the input name into words based on spaces
                      const words = name.split(" ");

                      // Filter out empty words (consecutive spaces)
                      const validWords = words.filter(
                        (word) => word.trim() !== ""
                      );

                      return validWords.length == 3 || lang == "ar"
                        ? "لا يمكن أن يكون الاسم أقل أو أكثر من 3 كلمات"
                        : "The name cannot be less or more than 3 words";
                    },
                  })}
                />
                {errors.name ? (
                  <Typography
                    fontSize={15}
                    fontFamily={"Noor-bold"}
                    color={"red"}
                    mt={1}
                    mr={1}
                  >
                    {errors.name?.message}
                  </Typography>
                ) : (
                  ""
                )}
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                // alignItems: 'center',
                marginBottom: "40px",
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  marginLeft: "10px",
                }}
              >
                <span
                  style={{
                    position: "absolute",
                    top: "-24px",
                    right: lang == "ar" ? "40px" : "unset",
                    left: lang == "ar" ? "unset" : "40px",
                    fontSize: "15px",
                    color: globalState?.isDarkMode ? COLORS.WHITE : "#2f8bc8",
                    background: globalState?.isDarkMode
                      ? COLORS.DARK_GRAY
                      : "#F5F5F5",
                    padding: "10px 20px",
                  }}
                >
                  {lang == "ar" ? "رقم الطالب" : "Student number"}
                </span>
                <input
                  type="text"
                  placeholder={lang == "ar" ? "رقم الطالب" : "Student Number"}
                  style={{
                    background: globalState?.isDarkMode
                      ? COLORS.SOFT_DARK
                      : "#F5F5F5",
                    border: "1px solid #2f8bc8",
                    padding: "20px 15px",
                    borderRadius: "5px",
                    outline: "none",
                    fontSize: "16px",
                    width: "100%",
                    color: globalState?.isDarkMode ? COLORS.WHITE : "black",
                    transition: "0.4s",
                    direction: lang == "ar" ? "rtl" : "ltr",
                  }}
                  {...register("phone", {
                    required:
                      lang == "ar"
                        ? `يجب ادخال رقم الطالب`
                        : `You must enter the student number`,
                    maxLength: {
                      value: 11,
                      message:
                        lang == "ar"
                          ? "يجب ان يكون 11 رقم فقط"
                          : "It should only be 11 numbers",
                    },
                    minLength: {
                      value: 11,
                      message:
                        lang == "ar"
                          ? "يجب ان يكون 11 رقم فقط"
                          : "It should only be 11 numbers",
                    },
                    pattern: {
                      value: /^(011|010|012|015)\d{8}$/,
                      message:
                        lang == "ar"
                          ? "الرقم غير صحيح"
                          : "The figure is incorrect",
                    },
                  })}
                />
                {errors.phone ? (
                  <Typography
                    fontSize={15}
                    fontFamily={"Noor-bold"}
                    color={"red"}
                    mt={1}
                    mr={1}
                  >
                    {errors.phone?.message}
                  </Typography>
                ) : (
                  ""
                )}
                {error?.response?.data?.errors?.phone?.[0] ===
                "The phone has already been taken." ? (
                  <Typography
                    fontSize={15}
                    fontFamily={"Noor-bold"}
                    color={"red"}
                    mt={1}
                    mr={1}
                  >
                    {lang == "ar"
                      ? "هذا الرقم مستخدم من قبل"
                      : "This number is already in use"}
                  </Typography>
                ) : (
                  ""
                )}
              </Box>
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  marginLeft: "10px",
                }}
              >
                <span
                  style={{
                    position: "absolute",
                    top: "-24px",
                    right: "40px",
                    fontSize: "15px",
                    color: globalState?.isDarkMode ? COLORS.WHITE : "#2f8bc8",
                    background: globalState?.isDarkMode
                      ? COLORS.DARK_GRAY
                      : "#F5F5F5",
                    padding: "10px 20px",
                  }}
                >
                  {lang == "ar" ? "رقم ولي الامر" : "Parent phone number"}
                </span>
                <input
                  type="text"
                  placeholder={
                    lang == "ar" ? " رقم ولي الامر" : "parent phone number"
                  }
                  style={{
                    background: globalState?.isDarkMode
                      ? COLORS.DARK_GRAY
                      : "#F5F5F5",
                    border: "1px solid #2f8bc8",
                    padding: "20px 15px",
                    borderRadius: "5px",
                    outline: "none",
                    fontSize: "16px",
                    width: "100%",
                    color: globalState?.isDarkMode ? COLORS.WHITE : "black",
                    transition: "0.4s",
                    direction: lang == "ar" ? "rtl" : "ltr",
                  }}
                  {...register("parent_phone", {
                    required:
                      lang == "ar"
                        ? `يجب ادخال رقم ولي الامر`
                        : `You must enter the guardian's number.`,
                    maxLength: {
                      value: 11,
                      message:
                        lang == "ar"
                          ? "يجب ان يكون 11 رقم فقط"
                          : "It should only be 11 numbers",
                    },
                    minLength: {
                      value: 11,
                      message:
                        lang == "ar"
                          ? "يجب ان يكون 11 رقم"
                          : "It should only be 11 numbers",
                    },
                    //   pattern: {
                    //     value: /^(011|010|012|015)\d{8}$/,
                    //     message: "الرقم غير صحيح",
                    //   },
                    validate: (value, data) => {
                      if (value === data.phone) {
                        return lang == "ar"
                          ? "رقم ولي الامر لا يمكن ان يساوي رقم الطالب"
                          : "The guardian's number cannot be equal to the student's number.";
                      } else {
                        return true;
                      }
                    },
                  })}
                />
                {errors.parent_phone ? (
                  <Typography
                    fontSize={15}
                    fontFamily={"Noor-bold"}
                    color={"red"}
                    mt={1}
                    mr={1}
                  >
                    {errors.parent_phone?.message}
                  </Typography>
                ) : (
                  ""
                )}
                {error?.response?.data?.errors?.phone?.[0] ===
                "The phone has already been taken." ? (
                  <Typography
                    fontSize={15}
                    fontFamily={"Noor-bold"}
                    color={"red"}
                    mt={1}
                    mr={1}
                  >
                    {lang == "ar"
                      ? " هذا الرقم مستخدم من قبل"
                      : "This number is already in use"}
                  </Typography>
                ) : (
                  ""
                )}
              </Box>
            </Box>
            <Box>
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  marginBottom: "40px",
                }}
              >
                <span
                  style={{
                    position: "absolute",
                    top: "-24px",
                    right: lang == "ar" ? "40px" : "unset",
                    left: lang == "ar" ? "unset" : "40px",
                    fontSize: "15px",
                    color: globalState?.isDarkMode ? COLORS.WHITE : "#2f8bc8",
                    background: globalState?.isDarkMode
                      ? COLORS.DARK_GRAY
                      : "#F5F5F5",
                    padding: "10px 20px",
                  }}
                >
                  {lang == "ar" ? "المحافظة" : "City"}
                </span>

                <select
                  name=""
                  id=""
                  placeholder={lang == "ar" ? "المحافظة" : "City"}
                  style={{
                    background: globalState?.isDarkMode
                      ? COLORS.DARK_GRAY
                      : "#F5F5F5",
                    border: "1px solid #2f8bc8",
                    padding: "20px 15px",
                    borderRadius: "5px",
                    outline: "none",
                    fontSize: "16px",
                    width: "100%",
                    color: globalState?.isDarkMode ? COLORS.WHITE : "black",
                    transition: "0.4s",
                    direction: lang == "ar" ? "rtl" : "ltr",
                  }}
                  {...register("city", {
                    required:
                      lang == "ar"
                        ? "يجب اختيار المحافظة"
                        : "You must choose the City",
                    validate: () =>
                      selectedCity != "اختر المحافظة" || lang == "ar"
                        ? "يجب اختيار المحافظة"
                        : "You must choose the City",
                  })}
                >
                  <option value="" disabled selected>
                    {lang == "ar" ? "اختر المحافظة" : "Choose a city"}
                  </option>
                  {governorates?.map((i, index) => {
                    return (
                      <>
                        <option key={index} value={i.value}>
                          {i.label}
                        </option>
                      </>
                    );
                  })}
                </select>
                {errors.city ? (
                  <Typography
                    fontSize={15}
                    fontFamily={"Noor-bold"}
                    color={"red"}
                    mt={1}
                    mr={1}
                  >
                    {errors.city?.message}
                  </Typography>
                ) : (
                  ""
                )}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "40px",
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  // marginRight: "10px",
                }}
              >
                <span
                  style={{
                    position: "absolute",
                    top: "-24px",
                    right: lang == "ar" ? "40px" : "unset",
                    left: lang == "ar" ? "unset" : "40px",
                    fontSize: "15px",
                    color: "#2f8bc8",
                    background: globalState?.isDarkMode
                      ? COLORS.DARK_GRAY
                      : "#F5F5F5",
                    padding: "10px 20px",
                  }}
                >
                  {lang == "ar" ? "البريد الالكتروني" : "Email"}
                </span>
                <input
                  type="email"
                  placeholder={lang == "ar" ? "البريد الالكتروني" : "Email"}
                  style={{
                    background: globalState?.isDarkMode
                      ? COLORS.DARK_GRAY
                      : "#F5F5F5",
                    border: "1px solid #2f8bc8",
                    padding: "20px 15px",
                    borderRadius: "5px",
                    outline: "none",
                    fontSize: "16px",
                    width: "100%",
                    color: globalState?.isDarkMode ? COLORS.WHITE : "black",
                    transition: "0.4s",
                    direction: lang == "ar" ? "rtl" : "ltr",
                  }}
                  {...register("email", {
                    required:
                      lang == "ar"
                        ? `يجب ادخال البريد الالكتروني`
                        : `You must enter an email address`,
                    pattern: {
                      value:
                        /^[a-zA-Z0-9._%+-]*[a-zA-Z][a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]*[a-zA-Z][a-zA-Z0-9.-]*\.[a-zA-Z]{2,}$/i,
                      message:
                        lang == "ar"
                          ? "البريد الاكتروني غير صالح"
                          : "Email is invalid",
                    },
                  })}
                />

                {errors.email ? (
                  <Typography
                    fontSize={15}
                    fontFamily={"Noor-bold"}
                    color={"red"}
                    mt={1}
                    mr={1}
                  >
                    {errors.email?.message}
                  </Typography>
                ) : (
                  ""
                )}
                {error?.response?.data?.errors?.email?.[0] ===
                  "The email has already been taken." ||
                error?.response?.status === 400 ? (
                  <Typography
                    fontSize={15}
                    fontFamily={"Noor-bold"}
                    color={"red"}
                    mt={1}
                    mr={1}
                  >
                    {lang == "ar"
                      ? " هذا الايميل مستخدم من قبل"
                      : "This email has already been used"}
                  </Typography>
                ) : (
                  ""
                )}
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "40px",
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  marginLeft: "10px",
                  height: "100%",
                }}
              >
                <Box>
                  <span
                    style={{
                      position: "absolute",
                      top: "-24px",
                      right: lang == "ar" ? "40px" : "unset",
                      left: lang == "ar" ? "unset" : "40px",
                      fontSize: "15px",
                      color: globalState?.isDarkMode ? COLORS.WHITE : "#2f8bc8",
                      background: globalState?.isDarkMode
                        ? COLORS.DARK_GRAY
                        : "#F5F5F5",
                      padding: "10px 20px",
                    }}
                  >
                    {lang == "ar" ? "كلمة السر" : "password"}
                  </span>
                  <input
                    type={passType}
                    placeholder={lang == "ar" ? "كلمة السر" : "password"}
                    style={{
                      background: globalState?.isDarkMode
                        ? COLORS.DARK_GRAY
                        : "#F5F5F5",
                      border: "1px solid #2f8bc8",
                      padding: "20px 15px",
                      borderRadius: "5px",
                      outline: "none",
                      fontSize: "16px",
                      width: "100%",
                      color: globalState?.isDarkMode ? COLORS.WHITE : "black",
                      transition: "0.4s",
                      direction: lang == "ar" ? "rtl" : "ltr",
                    }}
                    {...register("password", {
                      required:
                        lang == "ar"
                          ? `يجب ادخال كلمة السر`
                          : "You should Enter Password",
                      minLength: {
                        value: 8,
                        message:
                          lang == "ar"
                            ? "يجب ادخال علي الاقل 8 قيم"
                            : "You must enter at least 8 values",
                      },
                    })}
                  />
                  {passType === "password" ? (
                    <RemoveRedEyeOutlined
                      onClick={togglePassword}
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: lang == "ar" ? "15px" : "unset",
                        right: lang == "ar" ? "unset" : "15px",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                        opacity: ".7",
                      }}
                    />
                  ) : (
                    <VisibilityOffOutlined
                      onClick={togglePassword}
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: lang == "ar" ? "15px" : "unset",
                        right: lang == "ar" ? "unset" : "15px",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                        opacity: ".7",
                      }}
                    />
                  )}
                </Box>

                {errors.password ? (
                  <Typography
                    fontSize={15}
                    fontFamily={"Noor-bold"}
                    color={"red"}
                    mt={1}
                    mr={1}
                  >
                    {errors.password?.message}
                  </Typography>
                ) : (
                  ""
                )}
              </Box>
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                }}
              >
                <span
                  style={{
                    position: "absolute",
                    top: "-24px",
                    right: "40px",
                    fontSize: "15px",
                    color: "#2f8bc8",
                    background: "#F5F5F5",
                    padding: "10px 20px",
                  }}
                >
                  {lang == "ar" ? "تأكيد كلمة السر" : "password confirmation"}
                </span>
                <input
                  type={passType}
                  placeholder={
                    lang == "ar" ? "تأكيد كلمة السر" : "password confirmation"
                  }
                  style={{
                    background: globalState?.isDarkMode
                      ? COLORS.DARK_GRAY
                      : "#F5F5F5",
                    border: "1px solid #2f8bc8",
                    padding: "20px 15px",
                    borderRadius: "5px",
                    outline: "none",
                    fontSize: "16px",
                    width: "100%",
                    color: globalState?.isDarkMode ? COLORS.WHITE : "black",
                    transition: "0.4s",
                    direction: lang == "ar" ? "rtl" : "ltr",
                  }}
                  {...register("password_confirmation", {
                    required:
                      lang == "ar"
                        ? `يجب ادخال تأكيد كلمة السر`
                        : `You must enter a confirmation password`,
                    minLength: {
                      value: 8,
                      message:
                        lang == "ar"
                          ? "يجب ادخال علي الاقل 8 قيم"
                          : "You must enter at least 8 values",
                    },
                    validate: (value) =>
                      value === password || lang == "ar"
                        ? "يجب أن تكون كلمة السر متطابقة"
                        : "Password must match",
                  })}
                />
                {errors.password_confirmation ? (
                  <Typography
                    fontSize={15}
                    fontFamily={"Noor-bold"}
                    color={"red"}
                    mt={1}
                    mr={1}
                  >
                    {errors.password_confirmation?.message}
                  </Typography>
                ) : (
                  ""
                )}
              </Box>
            </Box>

            <Box
              mt={5}
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Button
                type="submit"
                sx={{
                  backgroundColor: "primary.main",
                  color: "white",
                  py: 1.5,
                  width: "60%",
                  fontSize: 12,
                  fontFamily: "Noor-bold",
                  boxShadow: `0px 3px 0px white, 0px 6px 0px black`,
                  mb: 2,
                  "&:hover": {
                    backgroundColor: "primary.dark",
                  },
                }}
              >
                {lang == "ar" ? "اشترك الان" : "Sign in"}{" "}
              </Button>
              <Button
                sx={{
                  fontSize: 18,
                }}
                color="primary"
                variant="text"
              >
                <Link
                  to={"/login"}
                  style={{ textDecoration: "none", color: globalState?.isDarkMode?COLORS.WHITE:"#003F7D" }}
                >
                  {lang == "ar"
                    ? "لدي حساب بالفعل"
                    : "I already have an account"}
                </Link>{" "}
              </Button>
            </Box>
          </form>
          {/* </FormProvider> */}
        </Column>
      </Box>
    </>
  );
}
