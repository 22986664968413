import { Box, Grid, ListItem, Typography } from "@mui/material";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { HashLoader } from "react-spinners";
import { COLORS } from "@/constants/Colors/colors";
import { api_url } from "@/constants/base_url";
import useCustomQuery from "../../../../hooks/useCustomQuery";
import { useSelector } from "react-redux";

export function CourseSummaries() {
  const { id, mid } = useParams();
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;

  const {
    data: filesData,
    isLoading: isfilesLoading,
    error: filesError,
    refetch: refetchfiles,
  } = useCustomQuery({
    url: `drive?type=module&id=${mid}`,
    lang,
    // data:{type: "Module",id:mid},
    key: ["module files", mid, lang],
    select: (data) => data?.data?.data,
  });
  // if (isModuleFilesError)
  const empty = (
    <Typography
      fontSize={32}
      fontFamily={"Noor-bold"}
      textAlign={"center"}
      sx={{ color: COLORS.WHITE, "&:hover": { color: COLORS.PRIMARY.MAIN } }}
    >
      { lang=="ar"?"لا يوجد ملازم":"There is no File"}
    </Typography>
  );
  const spinner = (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <HashLoader color={COLORS.PRIMARY.MAIN} />
    </div>
  );

  const content = (
    <Grid
      container
      spacing={{ xs: 2, md: 3 }}
      columns={{ xs: 4, sm: 6, md: 9, lg: 16 }}
    >
      {/* comment */}
      {filesData?.map((file, index) => (
        <Grid item xs={2} sm={3} lg={4} key={index} marginBottom={3}>
          <a
            key={index}
            download={file.name}
            href={api_url.slice(0, -7) + file.url}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none", color: COLORS.DARK_GRAY }}
          >
            <ListItem
              sx={{
                padding: "30px",
                bgcolor: COLORS.DARK_GRAY,
                borderRadius: "10px",
                textAlign: "center",
                display: "block",
                color: "white",
                borderBottom: "5px solid white",
                boxShadow: `0px 6px 0px ${COLORS.PRIMARY.MAIN}`,
              }}
            >
              <Typography fontFamily={"Noor-bold"} fontSize={40}>
                {index < 9 ? `0${index + 1}` : index + 1}
              </Typography>
              <Typography fontFamily={"Noor-bold"} fontSize={20}>
                {file.name}
              </Typography>
            </ListItem>
          </a>
        </Grid>
      ))}
    </Grid>
  );

  return (
    <>
      <Box>
        {isfilesLoading ? spinner : filesData?.length > 0 ? content : empty}
      </Box>
    </>
  );
}

// export default CourseSummaries;
