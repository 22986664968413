import { useQuery } from "@tanstack/react-query";
import useAxiosConfig from "../Admin/Shared/Helpers/axios/useAxiosConfig";

export default function useCustomPostQuery({
  url,
  key,
  data,
  page,
  ...options
}) {
  const axios = useAxiosConfig();
  return useQuery({
    queryKey: page ? [key, page] : [key],
    queryFn: () => axios.post(url, data, options),
    select: (data) => data?.data,
    ...options,
  });
}
