import React from "react";
import { Hero } from "./Hero/Hero";
import { MySubscriptions } from "./MySubscriptions/MySubscriptions";
import { CoursesForYou } from "./CoursesForYou/CoursesForYou";

export const Main = () => {
  return (
    <>
      <Hero />
      <MySubscriptions />
      <CoursesForYou />
    </>
  );
};
