import { useEffect, useRef } from "react";
import Plyr from "plyr";
import "plyr/dist/plyr.css";
import poster from "@assets/images/poster.png";

export function VideoPlayer({ VideoLink }) {
  const ref = useRef(null);
  const positionRef = useRef(null);

  const isYoutube =
    VideoLink.includes("www.youtube.com/watch") ||
    VideoLink.includes("youtu.be");

  useEffect(() => {
    const playerRef = isYoutube ? "#player" : ref.current;

    const player = new Plyr(playerRef, {
      controls: [
        "play-large", // The large play button in the center
        "restart", // Restart playback
        "rewind", // Rewind by the seek time (default 10 seconds)
        "play", // Play/pause playback
        "fast-forward", // Fast forward by the seek time (default 10 seconds)
        "progress", // The progress bar and scrubber for playback and buffering
        "current-time", // The current time of playback
        "duration", // The full duration of the media
        "mute", // Toggle mute
        "volume", // Volume control
        "captions", // Toggle captions
        "settings", // Settings menu
        "fullscreen", // Toggle fullscreen
        "Quality",
      ],
      youtube: {
        showRelatedVideos: false,
      },
      autoplay: false,
      loop: { active: true },
    });
    document.querySelectorAll(".ytp-chrome-top").forEach((button) => {
      button.style.display = "none";
    });
  }, [VideoLink, isYoutube]);

  useEffect(() => {
    const interval = setInterval(() => {
      const positionElement = positionRef.current;

      if (positionElement && ref.current) {
        const videoRect = ref.current.getBoundingClientRect();
        const randomTop = Math.floor(
          Math.random() * (videoRect.height - positionElement.offsetHeight)
        );
        const randomLeft = Math.floor(
          Math.random() * (videoRect.width - positionElement.offsetWidth)
        );
        positionElement.style.top = `${randomTop}px`;
        positionElement.style.left = `${randomLeft}px`;
      }
    }, 1500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      {isYoutube ? (
        <div
          id="player"
          data-plyr-provider="youtube"
          data-plyr-embed-id="bTqVqk7FSmY"
          style={{
            borderRadius: "20px",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "13%",
              zIndex: "10",
              backgroundColor: "transparent",
              opacity: "0.5",
            }}
          ></div>
          <div
            style={{
              position: "absolute",
              bottom: "6%",
              left: "0",
              width: "100%",
              height: "25%",
              zIndex: "10",
              backgroundColor: "transparent",
              opacity: "0.5",
            }}
          ></div>
          <iframe
            width="560"
            height="315"
            src={VideoLink}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      ) : (
        <div
          key={VideoLink}
          style={{
            borderRadius: "20px",
            overflow: "hidden",
          }}
        >
          <video ref={ref} id="player" controls poster={poster}>
            <source src={VideoLink} type="video/mp4" />
          </video>
        </div>
      )}
    </>
  );
}
