import React, { useEffect, useState } from "react";
import { useUserSubsContext } from "../Context/UserSubsContext";
import { Box, Button, Container, Input } from "@mui/material";
import NoItemsText from "@shared/NoItemText/NoItemText";
import CourseCard from "@/modules/home/components/atoms/CourseCard";
import { api_url } from "@/constants/base_url";
import { Timer } from "@shared/Timer/Timer";
import { currentUser } from "@shared/Helpers/encryptionHelpers";
import { HashLoader } from "react-spinners";
import { COLORS } from "@/constants/Colors/colors";
import Paginator from "@shared/Pagination/Paginator";
import { useAllCoursesContext } from "../Context/AllCoursesContext";
import ColorButton from "@shared/Buttons/ColorButton/ColorButton";
import { Search } from "@mui/icons-material";
import { useSelector } from "react-redux";

export const AllCourses = () => {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const { data, isPending, setPage, page, totalPages, setQuery, query } =
    useAllCoursesContext();
  const {
    data: mySubs,
    isPending: isMySubsPending,
    ids,
  } = useUserSubsContext();
  const [keyword, setKeyword] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  useEffect(() => {
    if (!keyword) {
      setQuery("");
    }
  }, [keyword]);
  if (isPending) {
    return (
      <Box
        sx={{
          minHeight: "75.5vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingY: 5,
        }}
      >
        <HashLoader color={COLORS.PRIMARY.MAIN} />
      </Box>
    );
  }
  return (
    <Box sx={{ minHeight: "84vh", direction: lang == "ar" ? "rtl" : "ltr" }}>
      {data?.data?.data?.length > 0 ? (
        <Box paddingY={5}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingY: 5,
            }}
          >
            <Paginator page={page} setPage={setPage} totalPages={totalPages} />
            <Box display={"flex"} alignItems={"center"} marginLeft={5}>
              <Input
                onChange={(e) => {
                  setKeyword(e?.target?.value);
                  if (e.target.value === "") {
                    setQuery("");
                  }
                }}
                type="text"
                placeholder={
                  lang == "ar" ? "ابحث عن كورس" : "Search for Course"
                }
                sx={{ fontSize: 20, outline: "none", border: "none" }}
              />
              <ColorButton
                sx={{
                  width: 5,
                  height: 37,
                  borderRadius: 0,
                  boxShadow: "0 0 0 0",
                }}
                onClick={() => {
                  setQuery(keyword);
                }}
              >
                <Search />
              </ColorButton>
            </Box>
          </Box>
          <Container
            maxWidth="2xl"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexWrap: "wrap",
              gap: 5,
            }}
          >
            {data?.data?.data?.map((item, i) => (
              <Box sx={{ position: "relative", width: "fit-content" }}>
                <CourseCard
                  width={"350px"}
                  key={item.id}
                  img={`${api_url.slice(0, -7)}${item.image.slice(1)}`}
                  name={item.name}
                  price={item.price}
                  type={item.isPayByModule ? "بالحصة" : "بالكورس"}
                  teacher={item.teacher}
                  description={item.description}
                  link={`/course/${item.id}`}
                  id={item.id}
                  bought={ids?.includes(+item.id)}
                />
                {ids?.includes(+item.id) && (
                  <Timer
                    time={
                      mySubs?.data?.data?.data?.find(
                        (x) => x.course_id == +item.id
                      )?.expires_at
                    }
                    sx={{ position: "absolute", top: 0, left: 0 }}
                  />
                )}
              </Box>
            ))}
          </Container>
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingY: 5,
          }}
        >
          <NoItemsText
            text={
              query ? "لا يوجد كورسات بهذا الأسم" : "لم يتم إضافة كورسات بعد"
            }
          />
        </Box>
      )}
    </Box>
  );
};
