import { COLORS } from "@/constants/Colors/colors";
import { api_url } from "@/constants/base_url";
import { Box, Button, Typography } from "@mui/material";
import ColorButton from "@shared/Buttons/ColorButton/ColorButton";
import useCustomQuery from "@/hooks/useCustomQuery";
import React, { useState } from "react";
import { SubscribeBox } from "../SubscribeBox/SubscribeBox";
import { useSelector } from "react-redux";

export const UserCourseHeader = ({ bought, course }) => {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const [subBoxOpen, setSubBoxOpen] = useState(false);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          minHeight: 400,
          paddingY: 5,
          bgcolor: COLORS.DARK_GRAY,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
            alignItems: "center",
            color: COLORS.WHITE,
          }}
        >
          <Typography sx={{ fontSize: 50, fontFamily: "Noor-regular" }}>
            {course?.name}
          </Typography>
          {!bought && (
            <Typography sx={{ fontSize: 30, fontFamily: "Noor-regular" }}>
              {course?.price} {lang == "ar" ? "جنيها" : "EGP"}
            </Typography>
          )}
          <Box sx={{ display: "flex", alignItems: "center", gap: 5 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 0,
                alignItems: "center",
                padding: 2,
                minWidth: 80,
                minHeight: 100,
                justifyContent: "center",
              }}
            >
              <Typography sx={{ fontSize: 40 }}>
                {course?.modulesCount}
              </Typography>
              <Typography sx={{ fontSize: 30, fontFamily: "Noor-regular" }}>
                {lang == "ar" ? "حصص" : "Lessons"}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 0,
                alignItems: "center",
                padding: 2,
                minWidth: 80,
                minHeight: 100,
                justifyContent: "center",
              }}
            >
              <Typography sx={{ fontSize: 40 }}>{course?.duration}</Typography>
              <Typography sx={{ fontSize: 30, fontFamily: "Noor-regular" }}>
                {+course?.duration === 2
                  ? lang == "ar"
                    ? "ساعتين"
                    : "Two hours"
                  : +course?.duration > 1 && +course?.duration <= 10
                  ? lang == "ar"
                    ? "ساعات"
                    : "Hours"
                  : lang == "ar"
                  ? "ساعة"
                  : "One hour"}
              </Typography>
            </Box>
          </Box>
          {!bought && (
            <ColorButton
              onClick={() => setSubBoxOpen(true)}
              sx={{ fontSize: 20 }}
            >
              {lang == "ar" ? "اشترك الآن" : "Subscribe now"}
            </ColorButton>
          )}
        </Box>
        <img src={`${api_url.slice(0, -7)}${course?.image}`} width={400} />
      </Box>
      {subBoxOpen && <SubscribeBox setOpen={setSubBoxOpen} course={course} />}
    </>
  );
};
