import useCustomMutation from "@/hooks/useCustomMutation";

export default function useUpdateCourse({ courseId, onSettled, refetch }) {
  const { mutate, isPending } = useCustomMutation({
    url: "course/update",
    invalidate: [["course", courseId], "courses"],
    method: "post",
    successMsg: "تم تعديل الكورس بنجاح",

    onSuccess: () => {
      refetch();
    },
    onSettled,
  });

  function updateCourse(data) {
    mutate({ ...data, id: courseId });
  }

  return { updateCourse, isPending };
}
