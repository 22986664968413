import { COLORS } from "@/constants/Colors/colors";
import { Box, Button, Grid } from "@mui/material";
import useCustomQuery from "@/hooks/useCustomQuery";
import NoItemsText from "@shared/NoItemText/NoItemText";
import Paginator from "@/components/Pagination/Paginator";
import React, { useEffect, useState } from "react";
import { HashLoader } from "react-spinners";
import { UserBookCard } from "./UserBookCard/UserBookCard";
import { api_url } from "@/constants/base_url";
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export const UserBookStore = () => {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const [selectedTeacher, setSelectedTeacher] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const { data, isPending, isSuccess } = useCustomQuery({
    url: `book/getAllBooks?page=${page}`,
    key: ["books", page, lang],
    lang,
  });

  console.log();
  const navigate = useNavigate();
  useEffect(() => {
    if (isSuccess) {
      setTotalPages(data?.data?.last_page);
    }
  }, [isSuccess, data]);
  return (
    <Box
      sx={{
        minHeight: "calc(100vh - 235px)",
        paddingY: 5,
        paddingX: 2,
        direction: lang == "ar" ? "rtl" : "ltr",
      }}
    >
      <Button
        onClick={() => {
          navigate("/my-orders");
        }}
        sx={{
          position: "fixed",
          bottom: 60,
          left: 60,
          color: COLORS.WHITE,
          bgcolor: COLORS.PRIMARY.MAIN,
          "&:hover": { bgcolor: COLORS.PRIMARY.MAIN },
          fontSize: 20,
        }}
      >
        <CollectionsBookmarkIcon fontSize="large" />
        {lang == "ar" ? "طلباتي" : "My orders"}
      </Button>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {/* <BookTeachers
          selectedTeacher={selectedTeacher}
          getSelectedTeacher={setSelectedTeacher}
        /> */}
        <Paginator page={page} setPage={setPage} totalPages={totalPages} />
      </Box>
      {isPending ? (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <HashLoader color={COLORS.PRIMARY.MAIN} />
        </Box>
      ) : data?.data?.data?.length > 0 ? (
        <Grid
          marginTop={2}
          container
          columns={{ xs: 4, sm: 6, lg: 12, xl: 16 }}
        >
          {data?.data?.data?.map((book) => (
            <Grid key={book.id} item xs={2} sm={3} lg={4} marginBottom={3}>
              <UserBookCard
                id={book.id}
                img={`${api_url.slice(0, -7)}${book.image}`}
                title={book.title}
                price={book.price}
                teacherName={book.instructor_name}
                available_amount={book.available_amount}
                isOpenForReservation={book.isOpenForReservation}
                key={book.id}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <NoItemsText text={lang == "ar" ? "لا يوجد كتب" : "There is no book"} />
      )}
    </Box>
  );
};
