import { COLORS } from "@/constants/Colors/colors";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function DisplayAllHeader({ text, link }) {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  return (
    <>
      <Box
        px={6}
        mb={6}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography
          color={"background.main"}
          fontSize={50}
          fontFamily={"Noor-bold"}
        >
          {text}
        </Typography>

        <Link
          to={link}
          style={{
            textDecoration: "none",
            color: "#2a2a2a",
            fontFamily: "Noor-bold",
            fontSize: "18px",
            color: globalState?.isDarkMode ? COLORS.WHITE : COLORS.BLACK,
          }}
        >
          {lang == "ar" ? " عرض الكل" : "Show All"}
        </Link>
      </Box>
    </>
  );
}
