import { COLORS } from "@/constants/Colors/colors";
import { Box, Button, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import logo from "@assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { currentUser } from "@shared/Helpers/encryptionHelpers";
import { UserAcc } from "./UserAcc/UserAcc";
import UserWallet from "./UserWallet/UserWallet";
import WalletIcon from "@mui/icons-material/Wallet";
import { useSelector } from "react-redux";
import ModeTuggle from "@/modules/settings/components/ModeTuggle";
import LangTuggle from "@/modules/settings/components/LangTuggle";

export const NavBar = () => {
  const navigate = useNavigate();
  const [logoutBox, setLogoutBox] = useState(false);
  const [walletOpen, setWalletOpen] = useState(false);
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;

  const openWallet = () => {
    setWalletOpen(true);
  };
  const openLogout = () => {
    setLogoutBox(true);
  };

  const closeLogout = () => {
    setLogoutBox(false);
  };

  const [delAccBox, setDelAccBox] = useState(false);

  const openDelAcc = () => {
    setDelAccBox(true);
  };

  const closeDelAcc = () => {
    setDelAccBox(false);
  };

  const userData = currentUser();

  useEffect(() => {
    if (userData?.role == "Admin") {
      navigate("/dashboard");
      scrollTo(0, 0);
    } else if (!userData) {
      navigate("/");
    }
  });

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 10,
        paddingY: 2,
        width: "100%",
        bgcolor: globalState?.isDarkMode
          ? COLORS.SOFT_DARK
          : COLORS.PRIMARY.MAIN,
        direction: lang === "ar" ? "rtl" : "ltr",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 20,
        }}
      >
        <img
          style={{ cursor: "pointer" }}
          src={logo}
          width={100}
          onClick={() => {
            navigate("/home");
          }}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 5,
            color: COLORS.WHITE,
          }}
        >
          <Link to={"/home"} style={{ textDecoration: "none" }}>
            <Typography
              fontSize={20}
              fontFamily={"Noor-bold"}
              color={COLORS.WHITE}
              sx={{
                transition: "0.4s",
                "&:hover": {
                  color: COLORS.PRIMARY.LIGHT,
                },
              }}
              display={"inline"}
            >
              {lang == "ar" ? "الرئيسية" : "Home"}
            </Typography>
          </Link>
          <Link to={"/my-subscriptions"} style={{ textDecoration: "none" }}>
            <Typography
              fontSize={20}
              fontFamily={"Noor-bold"}
              color={COLORS.WHITE}
              sx={{
                transition: "0.4s",
                "&:hover": {
                  color: COLORS.PRIMARY.LIGHT,
                },
              }}
              display={"inline"}
            >
              {lang == "ar" ? " اشتراكاتي" : "My subscriptions"}
            </Typography>
          </Link>
          <Link to={"/my-stats"} style={{ textDecoration: "none" }}>
            <Typography
              fontSize={20}
              fontFamily={"Noor-bold"}
              color={COLORS.WHITE}
              sx={{
                transition: "0.4s",
                "&:hover": {
                  color: COLORS.PRIMARY.LIGHT,
                },
              }}
              display={"inline"}
            >
              {lang == "ar" ? "انجازاتي" : "Achievements"}
            </Typography>
          </Link>
          <Link to={"/book-store"} style={{ textDecoration: "none" }}>
            <Typography
              fontSize={20}
              fontFamily={"Noor-bold"}
              color={COLORS.WHITE}
              sx={{
                transition: "0.4s",
                "&:hover": {
                  color: COLORS.PRIMARY.LIGHT,
                },
              }}
              display={"inline"}
            >
              {lang == "ar" ? "متجر الكتب" : "Book store"}
            </Typography>
          </Link>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "2rem",
        }}
      >
        <Button
          sx={{
            bgcolor: globalState?.isDarkMode
              ? COLORS.SOFT_DARK
              : COLORS.PRIMARY.MAIN,
            color: globalState?.isDarkMode ? COLORS.WHITE : COLORS.WHITE,
            transition: "0.3s",
            width: "5%",
          }}
        >
          <LangTuggle />
        </Button>
        <Button
          sx={{
            bgcolor: globalState?.isDarkMode
              ? COLORS.SOFT_DARK
              : COLORS.PRIMARY.MAIN,
            color: globalState?.isDarkMode ? COLORS.WHITE : COLORS.WHITE,
            transition: "0.3s",
            width: "5%",
          }}
        >
          <ModeTuggle p={0} />
        </Button>
        <Box
          sx={{
            color: COLORS.WHITE,
            cursor: "pointer",
            fontSize: "3rem",
            transition: "0.4s",
            // width: "400px",
            "&:hover": {
              color: COLORS.PRIMARY.LIGHT,
            },
          }}
          onClick={() => {
            openWallet();
          }}
        >
          <WalletIcon color={COLORS.PRIMARY.LIGHT} fontSize={"1rem"} />
        </Box>
        <UserAcc openLogout={openLogout} openDelAcc={openDelAcc} />
      </Box>
      {walletOpen && <UserWallet open={walletOpen} setOpen={setWalletOpen} />}
    </Box>
  );
};
