import { api_url } from "@/constants/base_url";
import { CloseOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";

export default function ImageFullSize({ image, setDisplayFullImage }) {
  return (
    <div className="full-image-container">
      <Box
        onClick={() => {
          setDisplayFullImage(false);
        }}
        sx={{
          position: "absolute",
          top: "20px",
          right: "20px",
          color: "white",
          cursor: "pointer",
        }}
      >
        <CloseOutlined fontSize="large" />
      </Box>
      <img
        src={`${api_url.slice(0, -7)}${image.slice(1)}`}
        style={{ borderRadius: "10px", width: "860px" }}
        alt=""
      />
    </div>
  );
}
