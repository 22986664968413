import { COLORS } from "@/constants/Colors/colors";
import { api_url } from "@/constants/base_url";
import { Box, Typography } from "@mui/material";
import { notifyError } from "@shared/Toast/toast";
import React from "react";
import { useSelector } from "react-redux";

export const UserCourseFile = ({ file, bought }) => {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  return (
    <a
      style={{ textDecoration: "none", fontFamily: "Noor-bold", width: "100%" }}
      onClick={(e) => {
        if (!bought) {
          e.preventDefault();
          notifyError(
            lang == "ar"
              ? "يجب شراء الكورس اولا"
              : "You must purchase the course first."
          );
        }
      }}
      target="blank"
      href={bought && `${api_url.slice(0, -7)}${file?.url}`}
    >
      <Box
        sx={{
          width: "100%",
          height: 50,
          bgcolor: COLORS.BOX,
          borderRadius: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "0px 4px 0px #2f8bc8",
          cursor: "pointer",
        }}
      >
        <Typography
          sx={{ textDecoration: "none", color: COLORS.DARK_GRAY, fontSize: 18 }}
        >
          {file?.name?.length > 20
            ? file?.name?.slice(0, 20) + "..."
            : file?.name}
        </Typography>
      </Box>
    </a>
  );
};
