import React, { useState } from "react";
import { Typography } from "@mui/material";
import { Box, TextField, InputAdornment } from "@mui/material";
import { useSelector } from "react-redux";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import useCustomQuery from "@/hooks/useCustomQuery";
import { COLORS } from "@/constants/Colors/colors";
import ColorButton from "@shared/Buttons/ColorButton/ColorButton";
import { notifySuccess } from "@shared/Toast/toast";
import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "@config/queryClient.config";
import { api_url } from "@/constants/base_url";
import { currentUser } from "@shared/Helpers/encryptionHelpers";
import Modal from "@shared/Modal/Modal";
import QRCode from "react-qr-code";
import { useNavigate } from "react-router-dom";
import { HashLoader } from "react-spinners";

export default function PaymentMethods() {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const [selectedValue, setSelectedValue] = useState("");
  const [paymentNumber, setPaymentNumber] = useState("");
  const [amount, setAmount] = useState("");
  const [paymentData, setPaymentData] = useState([]);
  const [open, setOpen] = useState(false);
  const [qrData, setQrData] = useState(false);
  const navigate = useNavigate();

  const { data } = useCustomQuery({
    url: `payment/getMethods`,
    key: ["getMethods", lang],
  });

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const { mutate, isPending } = useMutation({
    mutationFn: (data) =>
      axios.post(`${api_url}payment/createInvoice`, data, {
        headers: {
          Authorization: `Bearer ${currentUser()?.token}`,
          lang: lang,
        },
      }),
    onSuccess: (data) => {
      queryClient.invalidateQueries(["shipped", lang]);
      notifySuccess(lang == "ar" ? "تم الشحن بنجاح" : "Shipped successfully");
      console.log("Response Data:", data?.data);
      setPaymentData(data?.data);
      setQrData(data?.data?.payment_data?.meezaQrCode);
      if (data?.data?.payment_data?.redirectTo) {
        navigate(`//${data?.data?.payment_data?.redirectTo}`);
      }
      setOpen(true);
    },
    onError: (err) => {
      console.log(err);
      notifyError(
        lang == "ar"
          ? "حدث خطأ ما, الرجاء المحاولة لاحقا"
          : "Something went wrong. Please try again later."
      );
    },
  });
  const submitHandler = () => {
    if (selectedValue == "4") {
      mutate({
        payment_method_id: selectedValue,
        payment_number: paymentNumber,
        amount,
      });
    }
    if (
      selectedValue == "3" ||
      selectedValue == "2" ||
      selectedValue == "12" ||
      selectedValue == "14"
    ) {
      mutate({
        payment_method_id: selectedValue,
        amount,
      });
    }
  };
  return (
    <Box sx={{ width: "100%" }}>
      <FormControl
        sx={{
          direction: lang === "ar" ? "rtl" : "ltr",
          width: "100%",
          marginTop: "1.5rem",
        }}
      >
        <FormLabel id="demo-radio-buttons-group-label">
          <Typography
            sx={{ fontSize: "1.2rem", textAlign: "center", color: "white" }}
          >
            {lang === "ar" ? "اشحن محفظتك" : "Charge your wallet"}
          </Typography>
        </FormLabel>

        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          value={selectedValue}
          onChange={handleChange}
          name="radio-buttons-group"
          sx={{ width: "100%" }}
        >
          {data?.data?.data.map((method) => (
            <FormControlLabel
              key={method.paymentId}
              value={method.paymentId}
              control={<Radio />}
              sx={{ width: "100%", marginBottom: "1rem" }}
              label={
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: " space-between",
                    width: "400px",
                    alignItems: "center",
                  }}
                >
                  {lang === "ar" ? method.name_ar : method.name_en}
                  <img src={method.logo} alt={method.name_en} width={100} />
                </Box>
              }
            />
          ))}
          <Box>
            <>
              {selectedValue == "4" && (
                <TextField
                  type="number"
                  label={lang == "ar" ? "رقم الدفع" : "Payment Number"}
                  value={paymentNumber}
                  onChange={(e) => {
                    setPaymentNumber(() => e.target.value);
                  }}
                  error={!paymentNumber}
                  helperText={
                    !paymentNumber && lang == "ar"
                      ? "يجب إضافة رقم الدفع"
                      : "Payment number must be added"
                  }
                  fullWidth
                  sx={{
                    color: COLORS.WHITE,
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#2f8bc8",
                    },
                    "& .MuiOutlinedInput-root": {
                      color: COLORS.WHITE,
                      "& .Mui-focused": {
                        borderColor: "#2f8bc8",
                        color: "white", // Border color when focused
                      },
                      "&:hover fieldset": {
                        borderColor: COLORS.WHITE, // Border color on hover
                      },
                    },
                    "& label": {
                      left: lang == "ar" ? "unset" : "0",
                      right: lang == "ar" ? "1.75rem" : "unset",
                      transformOrigin: "right",
                      fontSize: "0.8rem",
                      color: "#2f8bc8",
                      bgcolor: COLORS.DARK_GRAY,
                    },
                    "& legend": {
                      textAlign: lang == "ar" ? "right" : "left",
                      fontSize: "0.8rem",
                    },
                    direction: "rtl",
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="end"></InputAdornment>
                    ),
                  }}
                />
              )}
              <TextField
                type="number"
                label={lang == "ar" ? "المبلغ" : "amount"}
                value={amount}
                onChange={(e) => {
                  setAmount(() => e.target.value);
                }}
                error={!paymentNumber}
                helperText={
                  !paymentNumber && lang == "ar"
                    ? "يجب إضافة المبلغ"
                    : "Amount must be added"
                }
                fullWidth
                sx={{
                  color: COLORS.WHITE,
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: "#2f8bc8",
                  },
                  "& .MuiOutlinedInput-root": {
                    color: COLORS.WHITE,
                    "& .Mui-focused": {
                      borderColor: "#2f8bc8",
                      color: "white", // Border color when focused
                    },
                    "&:hover fieldset": {
                      borderColor: COLORS.WHITE, // Border color on hover
                    },
                  },
                  "& label": {
                    left: lang == "ar" ? "unset" : "0",
                    right: lang == "ar" ? "1.75rem" : "unset",
                    transformOrigin: "right",
                    fontSize: "0.8rem",
                    color: "#2f8bc8",
                    bgcolor: COLORS.DARK_GRAY,
                  },
                  "& legend": {
                    textAlign: lang == "ar" ? "right" : "left",
                    fontSize: "0.8rem",
                  },
                  direction: "rtl",
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="end"></InputAdornment>
                  ),
                }}
              />
            </>
            <ColorButton
              //   isLoading={isLoading}
              sx={{ marginTop: "1rem" }}
              onClick={submitHandler}
            >
              {isPending ? (
                <HashLoader color="#ffffff" size={24} />
              ) : lang == "ar" ? (
                "اشحن"
              ) : (
                "Charge"
              )}
            </ColorButton>
          </Box>
          {paymentData && (
            <>
              <Modal maxWidth={"sm"} open={open} closeFn={() => setOpen(false)}>
                {paymentData?.invoice_id && (
                  <Typography
                    h1
                    sx={{ fontSize: "1.3rem", textAlign: "center" }}
                  >
                    {lang == "ar" ? "رقم الفاتورة :" : "invoice number :" + " "}
                    {paymentData?.invoice_id}{" "}
                  </Typography>
                )}
                {paymentData?.payment_data?.meezaReference && (
                  <Typography
                    h1
                    sx={{ fontSize: "1.3rem", textAlign: "center" }}
                  >
                    {lang == "ar"
                      ? "meeza Reference:"
                      : "meeza Reference :" + " "}
                    {paymentData?.payment_data?.meezaReference}{" "}
                  </Typography>
                )}
                {paymentData?.payment_data?.expireDate && (
                  <Typography
                    h1
                    sx={{ fontSize: "1.3rem", textAlign: "center" }}
                  >
                    {lang == "ar" ? "expire Date :" : "expire Date :" + " "}
                    {paymentData?.payment_data?.expireDate}{" "}
                  </Typography>
                )}
                {paymentData?.payment_data?.fawryCode && (
                  <Typography
                    h1
                    sx={{ fontSize: "1.3rem", textAlign: "center" }}
                  >
                    {lang == "ar" ? "كود فوري : " : "Fawry Code : " + " "}{" "}
                    {paymentData?.payment_data?.fawryCode}
                  </Typography>
                )}
                {paymentData?.payment_data?.amanCode && (
                  <Typography
                    h1
                    sx={{ fontSize: "1.3rem", textAlign: "center" }}
                  >
                    {lang == "ar" ? "كود امان : " : "Aman Code : " + " "}{" "}
                    {paymentData?.payment_data?.amanCode}
                  </Typography>
                )}
                {paymentData?.payment_data?.masaryCode && (
                  <Typography
                    h1
                    sx={{ fontSize: "1.3rem", textAlign: "center" }}
                  >
                    {lang == "ar" ? "كود مصاري : " : "Masary Code : " + " "}{" "}
                    {paymentData?.payment_data?.masaryCode}
                  </Typography>
                )}
                {qrData && <QRCode value={qrData} />}
              </Modal>
            </>
          )}
        </RadioGroup>
      </FormControl>
    </Box>
  );
}
