import { COLORS } from "@/constants/Colors/colors";
import { Box, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

export const UserCourseDescription = ({ description }) => {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  return (
    <Box sx={{ padding: 5 }}>
      <Typography
        sx={{
          fontSize: 50,
          fontFamily: "Noor-bold",
          color: globalState?.isDarkMode ? COLORS.WHITE : COLORS.DARK_GRAY,
          marginBottom: 5,
        }}
      >
        {lang == "ar" ? "محتوي الكورس" : "Course content"}
      </Typography>
      <Typography sx={{ fontSize: 30, fontFamily: "Noor-bold" }}>
        {description}
      </Typography>
    </Box>
  );
};
