import { Box, Button } from "@mui/material";
import { Link } from "react-router-dom";
import logo from "@assets/images/logo.png";
import { useTheme } from "@mui/material/styles";
import { COLORS } from "@/constants/Colors/colors";
import ModeTuggle from "@/modules/settings/components/ModeTuggle";
import { useSelector } from "react-redux";
import LangTuggle from "@/modules/settings/components/LangTuggle";
export default function NavBar({ bar }) {
  const theme = useTheme();
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;

  return (
    <>
      <Box
        backgroundColor={globalState?.isDarkMode ? COLORS.SOFT_DARK : "white"}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        px={5}
        sx={{ direction: lang == "ar" ? "rtl" : "ltr" }}
      >
        <Link
          to={"/"}
          style={{
            textDecoration: "none",
          }}
        >
          <img
            src={logo}
            alt="logo"
            className="logo"
            style={{
              width: "20%",
              [theme.breakpoints.down("lg")]: {
                width: "70%", // Apply smaller font size on small screens
              },
              padding: "5px",
            }}
          />
        </Link>
        <Box
          display={"flex"}
          justifyContent={"center"}
          gap={4}
          alignItems={"center"}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <ModeTuggle />
            <LangTuggle />
          </Box>
          <Link
            to={"/login"}
            style={{ textDecoration: "none", color: "white" }}
          >
            <Button
              sx={{
                color: "white",
                backgroundColor: COLORS.SECONDARY.MAIN,
                borderRadius: "8px",
                px: 4,
                py: 1,
                fontSize: 16,
                [theme.breakpoints.down("lg")]: {
                  fontSize: 14,
                  display: "none", // Apply smaller font size on small screens
                },
                fontFamily: "Noor-bold",
                "&:hover": {
                  backgroundColor: COLORS.PRIMARY.MAIN,
                },
              }}
            >
              {lang == "ar" ? "تسجيل الدخول" : "Log in"}{" "}
            </Button>
          </Link>
        </Box>
      </Box>
    </>
  );
}
