import { governorates } from "@/hooks/governorates";
import { COLORS } from "@/constants/Colors/colors";
import { api_url } from "@/constants/base_url";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";

export const UserBookOrder = ({ order }) => {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const formatAddress = () => {
    return `${governorates.find((x) => x.value == order.city)?.label},${
      order?.address
    }`;
  };
  return (
    <Box
      sx={{
        width: 300,
        height: 600,
        bgcolor: globalState?.isDarkMode ? COLORS.DARK_GRAY : COLORS.WHITE,
        borderRadius: 2,
        boxShadow: "0 0 8px rgba(0, 0, 0, 0.3)",
        padding: 2,
        display: "flex",
        flexDirection: "column",
        gap: 5,
      }}
    >
      {/* <img
        style={{ height: 300, borderRadius: 6 }}
        src={`${api_url.slice(0, -7)}${order.reservation_image}`}
      /> */}
      <Typography
        sx={{
          overflowWrap: "break-word",
          direction: lang == "ar" ? "rtl" : "ltr",
        }}
      >{`${lang == "ar" ? "الاسم:" : "Name : "} ${order.name}`}</Typography>
      <Typography
        sx={{
          overflowWrap: "break-word",
          direction: lang == "ar" ? "rtl" : "ltr",
        }}
      >{`${lang == "ar" ? "الرقم:" : "Phone number : "} ${
        order.phone
      }`}</Typography>
      <Typography
        sx={{
          overflowWrap: "break-word",
          direction: lang == "ar" ? "rtl" : "ltr",
        }}
      >{`${lang == "ar" ? "الرقم الثاني: " : "Secondary phone number : "} ${
        order.secondary_phone
      }`}</Typography>
      <Typography
        sx={{
          overflowWrap: "break-word",
          direction: lang == "ar" ? "rtl" : "ltr",
        }}
      >{`${
        lang == "ar" ? "العنوان : " : "Address : "
      } ${formatAddress()}`}</Typography>
      {/* <Typography>{`${lang == "ar" ? "رقم الحجز:" : "Reservation number :"} ${
        order.reservation_phone
      }`}</Typography> */}
      <Typography
        sx={{
          overflowWrap: "break-word",
          direction: lang == "ar" ? "rtl" : "ltr",
        }}
      >{`${lang == "ar" ? "التاريخ و الوقت: " : "Time & Date : "} ${
        order.reservation_date
      }`}</Typography>
      <Typography
        sx={{
          overflowWrap: "break-word",
          direction: lang == "ar" ? "rtl" : "ltr",
        }}
      >{`${lang == "ar" ? "عدد النسخ:" : "Quantity : "} ${
        order.quantity
      }`}</Typography>
      <Typography
        sx={{
          textAlign: "center",
          width: "fit-content",
          margin: "0 auto",
          paddingX: 2,
          paddingY: 1,
          borderRadius: 2,
          bgcolor: order.isConfirmed ? COLORS.SUCCESS : "yellow",
          color: COLORS.DARK_GRAY,
        }}
      >
        {order.isConfirmed ? "تم بنجاح" : "قيد الانتظار"}
      </Typography>
    </Box>
  );
};
