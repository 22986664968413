import { COLORS } from "@/constants/Colors/colors";
import { Box, Container } from "@mui/material";
import useCustomQuery from "@/hooks/useCustomQuery";
import React, { useEffect } from "react";
import { HashLoader } from "react-spinners";
import DisplayAllHeader from "../atoms/DisplayAllHeader";
import NoItemsText from "@shared/NoItemText/NoItemText";
import CourseCard from "@/modules/home/components/atoms/CourseCard";
import { api_url } from "@/constants/base_url";
import { Timer } from "@shared/Timer/Timer";
import { useUserSubsContext } from "../../Context/UserSubsContext";
import { useAllCoursesContext } from "../../Context/AllCoursesContext";
import { useSelector } from "react-redux";
export const CoursesForYou = () => {
  // const {data, isPending} = useCustomQuery({
  //     key:["courses",page=1],
  //     url:"course/all",
  //     select:(data) => data
  // })
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;

  const { data, isPending, setPage, setQuery } = useAllCoursesContext();
  const {
    data: mySubs,
    isPending: isMySubsPending,
    ids,
  } = useUserSubsContext();

  useEffect(() => {
    setPage(1);
    setQuery("");
  });
  if (isPending) {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingY: 5,
        }}
      >
        <HashLoader color={COLORS.PRIMARY.MAIN} />
      </Box>
    );
  }
  return (
    <>
      <Box py={10} sx={{ direction: lang == "ar" ? "rtl" : "ltr" }}>
        <DisplayAllHeader
          text={lang == "ar" ? "الكورسات" : "Courses"}
          link={"/all-courses"}
        />
        {data?.data?.data?.length > 0 ? (
          <Container
            maxWidth="2xl"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 5,
            }}
          >
            {data?.data?.data?.slice(0, 4).map((item, i) => (
              <Box sx={{ position: "relative", width: "fit-content" }}>
                <CourseCard
                  width={"350px"}
                  key={item.id}
                  img={`${api_url.slice(0, -7)}${item.image.slice(1)}`}
                  name={item.name}
                  price={item.price}
                  description={item.description}
                  type={
                    item.isPayByModule
                      ? lang == "ar"
                        ? "بالحصة"
                        : "Lesson"
                      : lang == "ar"
                      ? "بالكورس"
                      : "Course"
                  }
                  teacher={item.teacher}
                  link={`/course/${item.id}`}
                  id={item.id}
                  bought={ids?.includes(+item.id)}
                />
                {ids?.includes(+item.id) && (
                  <Timer
                    time={
                      mySubs?.data?.data?.data?.find(
                        (x) => x.course_id == +item.id
                      )?.expires_at
                    }
                    sx={{ position: "absolute", top: 0, left: 0 }}
                  />
                )}
              </Box>
            ))}
          </Container>
        ) : (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingY: 5,
            }}
          >
            <NoItemsText
              text={
                lang == "ar"
                  ? "لا توجد كورسات حتي الآن"
                  : "There is no courses yet"
              }
            />
          </Box>
        )}
      </Box>
    </>
  );
};
