import { COLORS } from "@/constants/Colors/colors";
import { Box } from "@mui/material";
import useCustomQuery from "@/hooks/useCustomQuery";
import NoItemsText from "@shared/NoItemText/NoItemText";
import Paginator from "@shared/Pagination/Paginator";
import React, { useEffect, useState } from "react";
import { HashLoader } from "react-spinners";
import { UserBookOrder } from "./UserBookOrder/UserBookOrder";
import { useSelector } from "react-redux";

export const UserBookOrders = () => {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const { data, isPending, isSuccess } = useCustomQuery({
    url: `bookOrder/showMyOrders`,
    key: ["my orders", lang],
    lang,
  });

  useEffect(() => {
    if (isSuccess) {
      setTotalPages(data?.data?.last_page);
    }
  }, [data, isSuccess]);
  return (
    <Box sx={{ width: "100%", minHeight: "calc(100vh - 235px)", paddingY: 5 }}>
      <Paginator page={page} totalPages={totalPages} setPage={setPage} />
      {isPending ? (
        <Box
          sx={{
            width: "100%",
            paddingY: 5,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <HashLoader color={COLORS.PRIMARY.MAIN} />
        </Box>
      ) : data?.data?.data?.length > 0 ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 2,
          }}
        >
          {data?.data?.data?.map((item) => (
            <UserBookOrder order={item} key={item.id} />
          ))}
        </Box>
      ) : (
        <NoItemsText
          text={lang == "ar" ? "لا توجد طلبات" : "There is no orders"}
        />
      )}
    </Box>
  );
};
