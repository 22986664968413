import { Box, Button, ListItem, Typography } from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import { CourseHomeWorks } from "./atoms/CourseHomeWorks";
import { COLORS } from "@/constants/Colors/colors";
import { useSelector } from "react-redux";

export const CourseHomeWorkList = ({ homework }) => {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const [countdown, setCountdown] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [started, setStarted] = useState(false);
  const [diff, setDiff] = useState(null);
  const startDate = new Date(homework?.data?.starts_at);
  const currentDate = Date.now();
  if (diff == null && startDate - currentDate) {
    setDiff(startDate - currentDate);
    setCountdown(startDate - currentDate);
  }
  //homework?.data?.data?.starts_at
  useEffect(() => {
    let timer;

    if (diff >= 0) {
      timer = setInterval(() => {
        if (diff >= 0) {
          setDiff((d) => d - 1000);
          setCountdown((c) => c - 1);
        }
      }, 1000);
    } else {
      setDisabled(false);
    }
    return () => clearInterval(timer);
  }, [diff]);
  const formatCountdown = () => {
    const seconds = Math.floor((diff / 1000) % 60);
    const minutes = Math.floor((diff / 1000 / 60) % 60);
    const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));

    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  };

  if (started) {
    return <CourseHomeWorks homeWork={homework} setStarted={setStarted} />;
  }
  return (
    <>
      <Box>
        <ListItem
          sx={{
            padding: "30px",
            borderRadius: "10px",
            textAlign: "center",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            color: "white",
            boxShadow: `0px 6px 0px ${COLORS.PRIMARY.MAIN}`,
            marginBottom: "30px",
            bgcolor: COLORS.DARK_GRAY,
          }}
        >
          <Box>
            <Typography fontFamily={"Noor-bold"} fontSize={24} mb={1}>
              {homework?.data?.title.length > 30
                ? `${homework?.data?.title.slice(0, 30)}...`
                : homework?.data?.title}
            </Typography>
          </Box>

          {!disabled && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Typography fontFamily={"Noor-bold"} fontSize={40}>
                {homework?.data?.grade}
              </Typography>
              <span
                style={{
                  margin: "0px 10px",
                  fontSize: "30px",
                  fontWeight: "700",
                }}
              >
                {" "}
                /{" "}
              </span>
              <Typography fontFamily={"Noor-bold"} fontSize={40}>
                {homework?.data?.homeWorkAnswer &&
                homework?.data?.homeWorkAnswer.grade != undefined
                  ? homework?.data?.homeWorkAnswer?.grade
                  : "-"}
              </Typography>
            </Box>
          )}

          <Box borderRadius={"4px"} overflow={"hidden"}>
            {disabled && (
              <Box sx={{ bgcolor: "black", color: "white" }}>
                {formatCountdown()}
              </Box>
            )}
            {(!homework?.data?.homeWorkAnswer ||
              homework?.data?.homeWorkAnswer.grade === null) && (
              <Button
                onClick={() => {
                  setStarted(true);
                }}
                disabled={disabled}
                sx={{
                  fontFamily: "Noor-regular",
                  bgcolor: `${disabled ? "grey.300" : COLORS.PRIMARY.MAIN}`,
                  padding: "15px 20px",
                  width: "150px",
                  color: "white",
                  transition: "0.4s",
                  borderRadius: "0",
                  "&:disabled": {
                    color: "grey.600",
                  },
                  "&:hover": {
                    bgcolor: COLORS.SECONDARY.MAIN,
                  },
                }}
              >
                {lang == "ar" ? "ابدأ الان" : "Start now"}
              </Button>
            )}
          </Box>
        </ListItem>
      </Box>
    </>
  );
};
