import React, { useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { COLORS } from "@/constants/Colors/colors";
import useCustomQuery from "@/hooks/useCustomQuery";
import { notifyError } from "@shared/Toast/toast";
import { useQuery } from "@tanstack/react-query";
import useAxiosConfig from "@shared/Helpers/axios/useAxiosConfig";
import { api_url } from "@/constants/base_url";
import { useSelector } from "react-redux";

export const UserCourseModule = ({ name, id, index, bought }) => {
  const navigate = useNavigate();
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const { isError, isSuccess, refetch } = useCustomQuery({
    url: `module?id=${id}`,
    key: ["module", id, lang],
    lang,
    enabled: false,
    staleTime: 0,
    gcTime: 0,
  });

  useEffect(() => {
    if (bought) {
      if (isError) {
        notifyError(
          lang == "ar"
            ? "يجب اجتياز امتحان الحصة السابقة"
            : "You must pass the previous semester exam."
        );
        return;
      } else if (isSuccess) {
        navigate(`course-taps/${id}`);
      }
    }
  }, [isError, isSuccess]);
  return (
    <>
      <Box
        key={index}
        sx={{
          background: "#d1d1d1",
          display: "flex",
          justifyContent: "space-between",
          margin: "25px 20px",
          borderRadius: "10px",
          borderBottom: "5px solid white",
          textAlign: "right",
          marginBottom: "20px",
          position: "relative",
          boxShadow: "0px 4px 0px #2f8bc8",
        }}
      >
        <div
          style={{
            background: "#2A2A28",
            padding: "20px",
            borderRadius: "0px 10px 10px 0px",
            fontSize: "20px",
            color: "white",
          }}
        >
          {String(index + 1).padStart(2, "0")}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            padding: "0px 20px",
            color: "black",
          }}
        >
          <Typography fontFamily={"Noor-bold"}>{name}</Typography>
          <Button
            onClick={() => {
              if (bought) {
                refetch();
              } else {
                notifyError(
                  lang == "ar"
                    ? "يجب عليك شراء الكورس اولا"
                    : "You must buy the course first."
                );
              }
            }}
            sx={{
              bgcolor: COLORS.PRIMARY.MAIN,
              fontFamily: "Noor-regular",
              padding: "7px 20px",
              color: "white",
              transition: "0.4s",
              "&:hover": {
                bgcolor: COLORS.WHITE,
                color: COLORS.PRIMARY.MAIN,
              },
            }}
          >
            {bought
              ? lang == "ar"
                ? "ابدا الحصه"
                : "Start Lesson"
              : lang == "ar"
              ? "ابدأ الآن"
              : "start now"}
          </Button>
        </div>
      </Box>
    </>
  );
};
