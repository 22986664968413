import useAxiosConfig from "@shared/Helpers/axios/useAxiosConfig";
import { notifyError, notifySuccess } from "@shared/Toast/toast";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSelector } from 'react-redux';

export default function useUpdateStudent() {
 const lang = useSelector((state) => state?.global?.lang);
  const instance = useAxiosConfig(lang);

  function updateStudent(data) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        lang: lang,
      },
    };

    return instance.post(`users/update`, data, config);
  }
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data) => updateStudent(data),
    onSuccess: () => {
      queryClient.invalidateQueries(["update student"]);
      queryClient.invalidateQueries(["students"]);
      notifySuccess(
        lang == "ar"
          ? "تم تعديل بيانات الطالب"
          : "Student data has been modified"
      );
    },
    onError: (err) => {
      notifyError(err?.response?.data?.message);
    },
  });
}
