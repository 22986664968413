import { COLORS } from "@/constants/Colors/colors";
import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";

export const Timer = ({ sx, time }) => {
  const [countdown, setCountdown] = useState("");

  useEffect(() => {
    const targetDate = new Date(time);

    const updateCountdown = () => {
      const now = new Date();
      const difference = targetDate - now;

      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      const formattedCountdown = `${days}d ${hours}h ${minutes}m ${seconds}s`;
      setCountdown(formattedCountdown);
    };

    const intervalId = setInterval(updateCountdown, 1000);

    return () => clearInterval(intervalId);
  }, [time]);

  return (
    <Box
      sx={{
        ...sx,
        minWidth: 140,
        minHeight: 40,
        bgcolor: COLORS.DANGER,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography sx={{ color: COLORS.WHITE }}>
        {countdown || "0D:0H:0M:0S"}
      </Typography>
    </Box>
  );
};
