import { COLORS } from "@/constants/Colors/colors";
import { Box, Typography } from "@mui/material";
import useCustomQuery from "@/hooks/useCustomQuery";
import React from "react";
import { HashLoader } from "react-spinners";
import { UserCourseExam } from "./UserCourseExam/UserCourseExam";
import { useSelector } from "react-redux";

export const UserCourseExams = ({ id, bought }) => {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const { data, isPending } = useCustomQuery({
    url: `exam/getCourseExamsStudent?course_id=${id}`,
    key: ["courseExamsForStudent", id, lang],
    lang,
  });
  return (
    <Box sx={{ padding: 5, width: "100%" }}>
      <Typography
        sx={{
          textAlign: "center",
          fontSize: 40,
          fontFamily: "Noor-bold",
          color: globalState?.isDarkMode ? COLORS.WHITE : COLORS.DARK_GRAY,
          marginBottom: 5,
        }}
      >
        {lang == "ar" ? "الإمتحانات الشاملة" : "Comprehensive exams"}
      </Typography>
      {isPending ? (
        <Box width={"100%"} display={"flex"} justifyContent={"center"}>
          <HashLoader color={COLORS.PRIMARY.MAIN} />
        </Box>
      ) : data?.data?.data?.length > 0 ? (
        data?.data?.data?.map((item, key) => (
          <UserCourseExam exam={item} index={key} bought={bought} />
        ))
      ) : (
        <Typography
          fontFamily={"Noor-bold"}
          mr={2}
          fontSize={50}
          textAlign={"center"}
          color={globalState?.isDarkMode ? COLORS.WHITE : COLORS.DARK_GRAY}
        >
          {lang == "ar" ? "لا يوجد إمتحانات" : "There are no exams"}
        </Typography>
      )}
    </Box>
  );
};
